import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService } from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AmountService {
  private apiUrl = environment.apiUrl + '/xigniteExchanges/amountRanges';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }
    getAmountRanges (): Observable<any> {
        return this.http.get<any>(this.apiUrl)
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getUser', [])))
          );
    }
    addAmountRange (item: any): Observable<any> {
        return this.http.post<any>(this.apiUrl, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The amount range has been added`)),
          catchError(this.handleError<any>('addAmountRange'))
        );
    }
    deleteAmountRange(value:any): Observable<any> {
      return this.http.delete<any>(this.apiUrl + '?value=' + value).pipe(
        map(response => {return null;},
        catchError(this.handleError<any>('deleteAmountRange Error')))
      );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          //this.alertService.error(`UserService: ${operation} failed: ${error}`);
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}
