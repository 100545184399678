import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Admin } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import { Globals } from '../shared';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currentUser: Admin;
  constructor(private router: Router, private authService: AuthenticationService, private userService: UserService, private globals: Globals) {
    this.authService.currentUser.subscribe(x => this.currentUser = x );
  }
  ngOnInit() {
  }
  logout() {
	  this.authService.logout();
    this.router.navigate(['/login']);
  }
}
