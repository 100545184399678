﻿import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from './_services';
@Component({
    selector: 'body',
    templateUrl: 'app.component.html'
})

export class AppComponent { 
	previousUrl: string;
  isLoggedIn = true;
	constructor(private renderer: Renderer2, private authService: AuthenticationService, private router: Router) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1);
          if(currentUrlSlug){
			      if (currentUrlSlug.startsWith('login') || currentUrlSlug.startsWith('register')) {
              this.isLoggedIn = false;
      				if(authService.isLoggedIn()) {
      					this.router.navigate(['home']);
      				}
	            this.renderer.addClass(document.body, 'login');
	            this.previousUrl = 'login';
    	      }else{
              let element;
              element = document.createElement('link');
              element.rel = "stylesheet";
              element.href = "assets/css/main.css";
              document.getElementsByTagName('head')[0].appendChild(element);
      				this.renderer.addClass(document.body, currentUrlSlug);
              this.renderer.addClass(document.body, 'nav-md');
      				this.previousUrl = currentUrlSlug;
    	        }
          }else{
          	this.renderer.addClass(document.body, 'nav-md');
          }
        }
      });
  }
}