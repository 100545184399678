export class Account {
  id: number;
  id_portfolio: number;
  account_number: string;
  photo_name: string;
  iban: string;
  id_type: number;
  type_name: string;
  id_currency: number;
  currency_shortcode: string;
  dividend_tax: number;
  is_active: number;
}
