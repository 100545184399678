﻿export class User {
    id: number;
    name: string = '';
    username: string = '';
    email: string = '';
    mobile: string = '';
    parent: number = 0;
    id_plan: number;
    plan_start: string;
    plan_end: string;
    paypal_plan: number = null;
    status: number = 1;
    date_login: string;
    date_created: string = '';
    date_modified: string = '';
    show_tips: number = 1;
    pwd_type: string = 'md5';
    address_line_1: string =null;
    address_line_2: string =null;
    state: string = null;
    city: string = null;
    country: string = null;
    postal_code: string = null;
}