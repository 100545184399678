import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog, MatPaginatorModule, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { tap, filter } from 'rxjs/operators';
import { PlanService } from '../_services';
import { Globals } from '../shared';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PlanComponent implements AfterViewInit, OnInit, OnDestroy { 
  reloadCom = false;
  expandedElement = null;
  rowIndex = 0;
  currIndex = -1;
  navigationSubscription;
  displayedColumns: string[] =  ['name','max_member','max_portfolio','max_account','max_product','price','status','is_public'];
  dataSource = new MatTableDataSource();
  totalItems = 100;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  keyword: string;
  sortCol  = {active: "name", direction: "asc"};
  // MatPaginator Output
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private planService: PlanService, private route: ActivatedRoute, private router: Router, private globals: Globals) { 
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: any)  => {
         this.initialiseInvites();
      });
  }
  initialiseInvites() {
    if(this.reloadCom){
       this.loadComponent();
    }
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if(params.sortBy){
           this.sortCol.active = params.sortBy;
           this.sortCol.direction = params.sortType;
        }
      });
    this.loadComponent();
    this.reloadCom = true;
  }
  loadComponent(){
    const page = +this.route.snapshot.paramMap.get('page');
    this.route.queryParams.subscribe(params => {
       this.keyword = (params['keyword']?params['keyword']:'');
    });
    this.getPlans(page,this.pageSize, this.keyword);
  }
  ngAfterViewInit() {
        this.paginator.page
            .pipe(
                tap(() => {})
            )
            .subscribe();
  }
  search(keyword: string): void {
      this.keyword = keyword.trim();
      this.getPlans(1,this.pageSize,keyword);
  }
  getPlans(offset: number = 1, limit: number = 10, keyword: string=''): void {
    this.planService.getPlans(offset,keyword,this.sortCol.active, this.sortCol.direction)
      .subscribe(plans => {
        this.totalItems = plans.pagination.total; 
        this.dataSource.data = plans.data;
        this.paginator.pageIndex = offset - 1;
      });
  }

  onPaginateChange(pageEvent):void {
      let currentPage = pageEvent.pageIndex + 1;
      this.getPlans(currentPage,pageEvent.pageSize, this.keyword);
      this.reloadCom = false;

      let queryParams = { sortBy: this.sortCol.active, sortType:this.sortCol.direction, keyword: null};
      if(this.keyword != ''){
        queryParams.keyword = this.keyword;
      }else{
        delete queryParams.keyword;
      }
      this.router.navigate(['plans/page/' + currentPage],{ queryParams: queryParams }).then(()=>{
        this.reloadCom = true;
      });
  }
  
  sortData(event):void {
	this.expandedElement = null;
	this.currIndex = -1;
      this.sortCol = event;
      let url = '/plans';
      if(this.paginator.pageIndex) {
        url += '/page/' + (this.paginator.pageIndex + 1)
        this.getPlans((this.paginator.pageIndex + 1),this.pageSize,this.keyword);
      }else{
        this.getPlans(1,this.pageSize,this.keyword);
      }
      let queryParams = { sortBy: event.active, sortType:event.direction, keyword: null};
      if(this.keyword != ''){
        queryParams.keyword = this.keyword;
      }else{
        delete queryParams.keyword;
      }
      this.reloadCom = false;
      this.router.navigate([url],{ queryParams: queryParams }).then(()=>{
        this.reloadCom = true;
      });
  }
  checkEvenOld(index){
  	if(index <= this.currIndex || this.currIndex == -1){
  		return (index % 2 == 0);
  	}
  	if((index + 1) % 2 == 0){
  		return true;
  	}
  	return false;
  }
  rowExpandedCollapsed(element, currIndex):void{
    if(this.expandedElement == element){
      this.expandedElement = null;
      this.currIndex = -1;
    }else{
      this.expandedElement = element;
      this.currIndex = currIndex;
    }
  }
}