﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// import { Admin, User } from '../_models';
import { AlertService } from '../_services/alert.service';


@Injectable({
  providedIn: 'root'
})
export class ScriptService {
    private apiUrl = environment.apiUrl + '/scripts';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }

    getScripts (keyword:string = '', sortBy:string = 'name', sortType:string = 'asc') {
      let params = new HttpParams();

      if(keyword != ''){
        params = params.append('keyword', keyword);
      }
      params = params.append('sortBy', `${sortBy}`);
      params = params.append('sortType', `${sortType}`);
      return this.http.get<any>(this.apiUrl, {params:params, headers: httpOptions.headers})
        .pipe(
            map(response => {
              if (response && response.status == 'success') {
                  return response.data;
              }
              return [];
          },
          catchError(this.handleError('getScripts', [])))
        );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}