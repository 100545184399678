import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MemberService} from '../_services/member.service';
import {filter, tap} from 'rxjs/operators';
import {UserService} from '../_services';
import {Globals} from '../shared';
import {GeneralUserComponent} from '../general-user.component';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent extends GeneralUserComponent implements AfterViewInit, OnInit, OnDestroy {
  reloadCom = false;
  navigationSubscription;
  displayedColumns: string[] =  ['favorite', 'name_first', 'profile', 'benchmark', 'currency'];
  dataSource = new MatTableDataSource();
  totalItems = 100;
  pageSize = 50;
  userId = 101;
  keyword: string;
  sortCol  = {active: 'name_first', direction: 'asc'};
  // MatPaginator Output
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public userService: UserService, public memberService: MemberService, private globals: Globals,
              private route: ActivatedRoute, private router: Router) {
    super(userService, memberService);
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(()  => {
        this.initialiseInvites();
      });
  }

  ngOnInit() {
    if (localStorage.getItem('userId')) {
      this.userId = +localStorage.getItem('userId');
    }
    this.route.queryParams
      .subscribe(params => {
        if (params.sortBy) {
          this.sortCol.active = params.sortBy;
          this.sortCol.direction = params.sortType;
        }
      });
    this.loadComponent();
    this.reloadCom = true;
  }

  ngOnDestroy() {
    /**
     * avoid memory leaks here by cleaning up after ourselves. If we
     * don't then we will continue to run our initialiseInvites()
     * method on every navigationEnd event.
     */
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => {})
      )
      .subscribe();
  }

  search(keyword: string): void {
    this.keyword = keyword.trim();
    this.getMembers(1, this.pageSize, this.userId, this.keyword);
  }

  onChange(userId: number): void {
    this.userId = userId;
    localStorage.setItem('userId', userId.toString());
    this.getMembers(1, this.pageSize, this.userId, this.keyword);
  }

  initialiseInvites() {
    if (this.reloadCom) {
        this.loadComponent();
    }
  }

  loadComponent() {
    const page = +this.route.snapshot.paramMap.get('page');
    this.route.queryParams.subscribe(params => {
      this.keyword = (params['keyword'] ? params['keyword'] : '');
    });
    this.getAllUsers();
    this.getMembers(page, this.pageSize, this.userId, this.keyword);
  }

  getMembers(offset: number = 1, limit: number = 10, userId: number = this.userId, keyword: string = ''): void {
    this.memberService.getMembers(userId, keyword, offset, limit, this.sortCol.active, this.sortCol.direction)
      .subscribe(members => {
        this.totalItems = members.pagination.total;
        this.dataSource.data = members.members;
        this.paginator.pageIndex = offset - 1;
      });
  }

  onPaginateChange(pageEvent): void {
    const memberPage = pageEvent.pageIndex + 1;
    this.getMembers(memberPage, pageEvent.pageSize, this.userId, this.keyword);
    this.reloadCom = false;

    const queryParams = { sortBy: this.sortCol.active, sortType: this.sortCol.direction, keyword: null};
    if (this.keyword) {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    this.router.navigate(['members/page/' + memberPage], { queryParams: queryParams }).then(() => {
      this.reloadCom = true;
    });
  }

  sortData(event): void {
    this.sortCol = event;
    let url = '/members';
    if (this.paginator.pageIndex) {
      url += '/page/' + (this.paginator.pageIndex + 1);
      this.getMembers((this.paginator.pageIndex + 1), this.pageSize, this.userId, this.keyword);
    } else {
      this.getMembers(1, this.pageSize, this.userId, this.keyword);
    }
    const queryParams = { sortBy: event.active, sortType: event.direction, keyword: null};
    if (this.keyword) {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    this.reloadCom = false;
    this.router.navigate([url], { queryParams: queryParams }).then(() => {
      this.reloadCom = true;
    });
  }
}
