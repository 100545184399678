import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Currency, Type} from '../_models';
import {Account} from '../_models/account';
import {CurrencyService, TypeService} from '../_services';
import {AccountService} from '../_services/account.service';
import {Globals} from '../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DialogComponent} from '../dialog/dialog.component';
import {PortfolioService} from '../_services/portfolio.service';
import {Portfolio} from '../_models/portfolio';
import {isNumber} from 'util';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {

  formBuilder: FormBuilder;
  accountForm: FormGroup;
  userId = 101;
  portfolioId: number;
  currencies: Currency[];
  portfolios: Portfolio[];
  portfolio: Portfolio;
  types: Type[];
  submitted: boolean;
  account = new Account();
  backupAccount = new Account();

  constructor(private accountService: AccountService, private portfolioService: PortfolioService, private currencyService: CurrencyService,
              private typeService: TypeService, private globals: Globals, private route: ActivatedRoute, private router: Router,
              public dialog: MatDialog) {
    this.formBuilder = new FormBuilder();
  }

  get f() { return this.accountForm.controls; }

  ngOnInit() {
    if (localStorage.getItem('accountUserId')) {
      this.userId = +localStorage.getItem('accountUserId');
    }
    if (localStorage.getItem('accountPortfolioId')) {
      this.portfolioId = +localStorage.getItem('accountPortfolioId');
    }
    this.accountForm = this.formBuilder.group({
      id_portfolio: [this.portfolioId, Validators.required],
      account_number: ['', Validators.required],
      photo_name: [],
      iban: [],
      id_type: [0],
      dividend_tax: [],
      id_currency: [null, Validators.required],
      is_active: [1]
    });

    this.getAccount();
    this.getAllPortfolio();
    this.getAllCurrencies();
    this.getTypesByUser();
  }

  getAllPortfolio(): void {
    this.portfolioService.getPortfolios(0, 200, '').subscribe(
      portfolios => this.portfolios = portfolios.portfolios
    );
  }

  getAllCurrencies(): void {
    this.currencyService.getAllCurrencies().subscribe(currencies => this.currencies = currencies.currencies);
  }

  getTypesByUser(userId: number = this.userId): void {
    this.typeService.getByUser(userId).subscribe(types => this.types = types.types);
  }

  getAccount(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.accountService.getAccount(id)
        .subscribe(data => {
          localStorage.setItem('accountPortfolioId', data.account.id_portfolio);
          if (data.account.id_type === null) {
            data.account.id_type = 0;
          }
          this.account = data.account;
          this.accountForm.reset(this.account);
          this.backupAccount = Object.assign({}, this.account);
        });
    } else {
      this.backupAccount.id_portfolio = this.portfolioId;
      this.backupAccount.account_number = null;
      this.backupAccount.photo_name = null;
      this.backupAccount.iban = null;
      this.backupAccount.id_currency = null;
      this.backupAccount.id_type = 0;
      this.backupAccount.dividend_tax = null;
      this.backupAccount.is_active = 1;
    }
  }

  onSubmit(account: Account): void {
    this.submitted = true;
    const values = this.accountForm.value;
    if (values.id_type === '0') {
      values.id_type = 0;
    }
    if (this.accountForm.invalid) {
      return;
    }
    this.globals.processing = true;
    values.dividend_tax = parseFloat(values.dividend_tax);
    if (account.id) {
      const data  = Object.assign({}, account);
      data.id_portfolio = values.id_portfolio;
      data.account_number = values.account_number;
      data.photo_name = values.photo_name;
      data.iban = values.iban;
      data.id_currency = values.id_currency;
      data.id_type = values.id_type;
      data.dividend_tax = values.dividend_tax;
      data.is_active = values.is_active;
      this.accountService.updateAccount(data).subscribe(data => { this.getAccount(); });
    } else {
      if (values.id_type === 0) {
        values.id_type = null;
      }
      this.accountService.addAccount(values).subscribe(data => {
        if (data && data.status === 'success') {
          this.backToList();
        }
      });
    }
  }

  onChangePortfolio(portfolioId): void {
    localStorage.setItem('accountPortfolioId', portfolioId);
    this.portfolioService.getPortfolio(portfolioId).subscribe(portfolio => {
      this.portfolio = portfolio.portfolio;
      this.getTypesByUser(this.portfolio.id_user);
      localStorage.setItem('accountUserId', this.portfolio.id_user.toString());
      localStorage.setItem('accountMemberId', this.portfolio.id_member.toString());
    });
  }

  onFileChanged(event) {
    const file = event.target.files[0];
  }

  backToList() {
    this.router.navigate(['accounts']);
  }

  openConfirmDialog(accountId): void {
    if (!accountId) {
      return;
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {type: true, message: 'Are you sure you want to delete this account?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountService.deleteAccount(accountId).subscribe(data => {
          if (!data) {
            this.router.navigate(['accounts']);
          }
        });
      }
    });
  }

  resetForm() {
    this.submitted = false;
    this.accountForm.reset(this.backupAccount);
  }

  focusDividendTax(dividendTax: any, value): void {
    dividendTax.type = 'number';
    dividendTax.value = parseFloat(value);
  }

  focusoutDividendTax(dividendTax: any, value): void {
    dividendTax.type = 'text';
    if (isNaN(parseFloat(value))) {
      dividendTax.value = '0%';
    } else {
      dividendTax.value = value + '%';
    }
  }
}
