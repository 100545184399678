﻿export class Admin {
    id: number;
    name: string = '';
    username: string = '';
    password: string = '';
    email: string = '';
    status: number = 1;
    date_login: string;
    roles: string = 'Administrator';
    permissions: string[];
}