import {UserService} from './_services';
import {User, Member} from './_models';
import {MemberService} from './_services/member.service';

export class GeneralUserComponent {
  users: User[];
  members: Member[];

  constructor(public userService: UserService, public memberService: MemberService) {}

  getAllUsers(): void {
    this.userService.getAllUsers('username', 'asc')
      .subscribe(users => {
        this.users = users.users;
      });
  }

  getMembersByUser(userId: number): void {
    this.memberService.getMembers(userId)
      .subscribe(members => {
        this.members = members.members;
      });
  }
}
