﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Admin } from '../_models';

@Injectable()
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Admin>;
    public currentUser: Observable<Admin>;
    constructor(private http: HttpClient, private router:Router) { 
        this.currentUserSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get currentUserValue(): Admin {
        return this.currentUserSubject.value;
    }
    isLoggedIn(): boolean{
        return (localStorage.getItem('currentUser')?true:false);
    }
    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/login`, { username: username, password: password },httpOptions)
            .pipe(map(response => {
                if (response && response.data.token) {
                    response.data.user.token = response.data.token;
                    localStorage.setItem('currentUser', JSON.stringify(response.data.user));
                    this.currentUserSubject.next(response.data.user);
                }
                return response.data.user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.router.navigate(['login']);
    }
}