import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../_services';
import { MemberService } from '../_services/member.service';
import { AccountService } from '../_services/account.service';
import { ProductService } from '../_services/product.service';
import { DataInspectionService } from '../_services/data-inspection.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {environment} from '../../environments/environment';
import {window} from 'rxjs/operators';
import {createElement} from '@angular/core/src/view/element';
const moment =  _moment;

declare function makeDatePicker(): any;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD-MMM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const validation: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let from = control.get('date_from');
    let to = control.get('date_to');
    let id_account = control.get('id_account');
    return (from && to && id_account && from.value != ""  && to.value != "" && from.value >= to.value) ? { 'validation': true} : null;
};

@Component({
    selector: 'data-inpsection-script',
    templateUrl: './data-inspection-script.component.html',
    styleUrls: ['./data-inspection-script.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          },

          {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})

export class DataInspectionScriptComponent implements OnInit {
    public GIAForm: FormGroup;
    public formBuilder: FormBuilder;

    public userId = 0;

    users = [];
    loading = false;
    isError = false;
    dataScript;

    link = '';
    hidden = true;

    constructor( public userService: UserService){
        this.formBuilder = new FormBuilder();
    }
    ngOnInit() {

        this.loadComponent();
        this.loadScript();

    }

    ngOnDestroy() {
    }

    loadScript() {
        let script = document.createElement('script');
        script.innerHTML = '';
        script.src = 'assets/js/jquery-ui.js';
        script.async = true;
        script.defer = true;
        document.getElementsByTagName('head')[0].appendChild(script);

        let element = document.createElement('link');
        element.rel = 'stylesheet';
        element.href = 'assets/css/jquery-ui.css';
        document.getElementsByTagName('head')[0].appendChild(element);
        setTimeout(() => {
            makeDatePicker();
        }, 1000);
    }

    loadComponent() {
        this.getAllUsers();
    }

    onChangeUser(userId: number): void {
        this.userId = userId;
        this.userService.getPrecomputeScript(userId).subscribe(data => {
            this.dataScript = data;
        });
    }

    getAllUsers(): void {
        this.userService.getAllInspectableUser('1').subscribe(users => {
            this.users = users;
        });
    }
}
