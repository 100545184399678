import { Component, OnInit } from '@angular/core';
import { UserService, PlanService, AlertService } from '../_services';
import { User } from '../_models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Options, Globals } from '../shared';
import {formatDate} from '@angular/common';
declare function makeDatePicker():any;
@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {
  submitted: boolean = false;
  formBuilder: FormBuilder;
  entityForm: FormGroup;
  entity = new  User();
  readonly = true;
  backupEntity = new User();
  planList: any;
  statusList: Options[] = [
      {'title': 'Active', 'value': '1'},
      {'title': 'Inactive', 'value': '0'}
  ];
  paypalList: Options[] = [{'title': 'Paypal', 'value': '2'}];

  constructor(private entityService: UserService, private router: Router, private planService: PlanService, private route: ActivatedRoute,
              private alertService: AlertService, private globals: Globals) {
    this.formBuilder = new FormBuilder();
  }
  get f() { return this.entityForm.controls; }
  ngOnInit() {
    this.entityForm = this.formBuilder.group({
        email: [null, { validators: [Validators.email, Validators.required]}],
        username: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
        name: ['', Validators.required],
        password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
        id_plan: ['', Validators.required],
        plan_start: ['', Validators.required],
        plan_end: ['', Validators.required],
        paypal_plan: ['2', Validators.required],
        status: ['', Validators.required]
    });
    this.getUser();
    this.getPlans();
    this.loadScript();
  }
  loadScript() {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/js/jquery-ui.js';
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const element = document.createElement('link');
    element.rel = 'stylesheet';
    element.href = 'assets/css/jquery-ui.css';
    document.getElementsByTagName('head')[0].appendChild(element);
    setTimeout(() => {
      makeDatePicker();
    }, 1000);
  }
  getPlans(page = 1) {
    this.planService.getPlans(page, '', 'name', 'asc', '')
      .subscribe(plans => {
        if (this.planList) {
          this.planList = this.planList.concat(plans.data);
        } else {
          this.planList = plans.data;
        }

        if (plans.pagination.current_page < plans.pagination.last_page) {
          this.getPlans(plans.pagination.current_page + 1);
        }
    });
  }
  getUser(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.entityService.getUser(id)
        .subscribe(data => {
          this.entity = data.user;
          this.entity.paypal_plan = 2;
          this.entityForm.reset(this.entity);
          this.backupEntity = Object.assign({}, this.entity);
          this.entityForm.get('password').clearValidators();
          this.entityForm.get('password').updateValueAndValidity();
          this.entityForm.updateValueAndValidity();
        });
    } else {
      this.readonly = false;
      this.entity.paypal_plan = 2;
      this.entityForm.reset(this.entity);
    }
  }
  onSubmit(entity) {
    this.submitted = true;
    if (this.entityForm.invalid) {
        return;
    }
    this.submitted = false;
    const values = this.entityForm.value;
    const data  = Object.assign({}, entity);
    data.name = values.name;
    data.status = values.status;
    this.globals.processing = true;
    data.payment_type  = values.paypal_plan;
    data.plan_start  = formatDate(values.plan_start, 'yyyy-MM-dd', 'en-US');
    data.plan_end  = formatDate(values.plan_end, 'yyyy-MM-dd', 'en-US');
    data.id_plan = values.id_plan;
    if (data.id) {
       if (values.password !== '') {
         data.password = values.password;
       }
       this.entityService.updateUser(data).subscribe(data => {this.getUser(); });
    } else {
       data.email = values.email;
       data.username = values.username;
       data.password = values.password;
       this.entityService.addUser(data).subscribe(data => {
         if (data && data.status === 'success') {
           this.backToList();
         }
       });
    }
  }
  deleteUser(entity) {
    this.entityService.deleteUser(entity.id).subscribe(data => {this.getUser();});
  }
  resetForm() {
    this.submitted = false;
    this.entityForm.reset(this.backupEntity);
  }
  generatePassword(length = 8) {
     let str = '';
     const chars = '!@#$%^&*()_+|0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
     const charsN = chars.length;
     for ( ; str.length < length; str += chars.charAt(Math.floor(Math.random() * charsN))); //Math.random().toString( 36 ).substr( 2 ) );
     this.entityForm.get('password').setValue(str);
  }
  backToList() {
    this.router.navigate(['users']);
  }
}
