import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GeneralUserComponent} from '../general-user.component';
import {UserService} from '../_services';
import {MemberService} from '../_services/member.service';
import {filter} from 'rxjs/operators';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {PortfolioService} from '../_services/portfolio.service';
import {AccountService} from '../_services/account.service';
import {Portfolio} from '../_models/portfolio';
import {Globals} from '../shared';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent extends GeneralUserComponent implements OnInit, OnDestroy {

  keyword: string;
  sortCol = {active: 'account_number', direction: 'asc'};
  userId = 101;
  memberId = 105;
  pageSize = 10;
  totalItems = 100;
  navigationSubscription;
  portfolios: Portfolio[];
  portfolioId: number;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['account_number', 'type', 'iban', 'is_active', 'currency'];
  data;
  currentPage = 1;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public userService: UserService, public memberService: MemberService, private portfolioService: PortfolioService,
              private accountService: AccountService, private route: ActivatedRoute, private router: Router, private globals: Globals) {
    super(userService, memberService);
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {});
  }

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
    /**
     * avoid memory leaks here by cleaning up after ourselves. If we
     * don't then we will continue to run our initialiseInvites()
     * method on every navigationEnd event.
     */
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  loadComponent() {
    this.currentPage = +this.route.snapshot.paramMap.get('page');
    if (localStorage.getItem('accountUserId')) {
      this.userId = +localStorage.getItem('accountUserId');
    }
    if (localStorage.getItem('accountMemberId')) {
      this.memberId = +localStorage.getItem('accountMemberId');
    }
    if (localStorage.getItem('accountPortfolioId')) {
      this.portfolioId = +localStorage.getItem('accountPortfolioId');
    }
    this.route.queryParams.subscribe(params => {
      this.keyword = params['keyword'] ? params['keyword'] : '';
      this.sortCol.active = params['sortBy'] ? params['sortBy'] : 'account_number';
      this.sortCol.direction = params['sortType'] ? params['sortType'] : 'asc';
    });
    this.getAllUsers();
    this.getMembersByUser(this.userId);
    this.getPortfolios();
    setTimeout(() => {
      this.getAccounts();
      }, 200);
  }

  getPortfolios(userId: number = this.userId, memberId: number = this.memberId) {
    this.portfolioService.getPortfolios(0, 200, '', userId, memberId)
      .subscribe(portfolios => { this.portfolios = portfolios.portfolios; });
  }

  getAccounts(portfolioId: number = this.portfolioId, keyword: string = this.keyword, offset: number = 1, limit: number = 200) {
    this.accountService.getAccounts(portfolioId, keyword, offset, limit, this.sortCol.active, this.sortCol.direction)
      .subscribe(accounts => {
        if (keyword !== '') {
          const filterAccountNumber = accounts.accounts.filter(
            t => t.account_number.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1
          );
          this.data = filterAccountNumber;
          this.totalItems = filterAccountNumber.length;
          this.paginator.pageIndex = 0;
        } else {
          this.data = accounts.accounts;
          this.totalItems = accounts.pagination.total;
          this.paginator.pageIndex = this.currentPage;
          if (this.currentPage !== 0) {
            this.paginator.pageIndex = this.currentPage - 1;
          }
        }
        this.sortData();
        setTimeout(() => {
          this.iterator(this.data, this.paginator.pageIndex);
        }, 200);
      });
  }

  iterator(data, page) {
    const end = (page + 1) * this.pageSize;
    const start = page * this.pageSize;
    this.dataSource.data = data.slice(start, end);
  }

  sortData() {
    const direction = this.sortCol.direction;
    if (this.sortCol.active === 'account_number') {
      this.data = this.data.sort(function(a, b) {
        if (direction === 'desc') {
          return (a.account_number > b.account_number) ? -1 : (a.account_number < b.account_number) ? 1 : 0;
        } else {
          return (a.account_number < b.account_number) ? -1 : (a.account_number > b.account_number) ? 1 : 0;
        }
      });
    } else if (this.sortCol.active === 'iban') {
      this.data = this.data.sort(function(a, b) {
        if (direction === 'desc') {
          return (a.iban > b.iban) ? -1 : (a.iban < b.iban) ? 1 : 0;
        } else {
          return (a.iban < b.iban) ? -1 : (a.iban > b.iban) ? 1 : 0;
        }
      });
    } else if (this.sortCol.active === 'type') {
      this.data = this.data.sort(function(a, b) {
        if (direction === 'desc') {
          return (a.type_name > b.type_name) ? -1 : (a.type_name < b.type_name) ? 1 : 0;
        } else {
          return (a.type_name < b.type_name) ? -1 : (a.type_name > b.type_name) ? 1 : 0;
        }
      });
    } else if (this.sortCol.active === 'currency') {
      this.data = this.data.sort(function(a, b) {
        if (direction === 'desc') {
          return (a.currency_shortcode > b.currency_shortcode) ? -1 : (a.currency_shortcode < b.currency_shortcode) ? 1 : 0;
        } else {
          return (a.currency_shortcode < b.currency_shortcode) ? -1 : (a.currency_shortcode > b.currency_shortcode) ? 1 : 0;
        }
      });
    }
  }

  onChangeUser(userId: number): void {
    this.userId = userId;
    localStorage.setItem('accountUserId', userId.toString());
    this.getMembersByUser(userId);
    setTimeout(() => {
      if (this.members.length > 0) {
        this.memberId = this.members[0].id;
        localStorage.setItem('accountMemberId', this.memberId.toString());
        setTimeout(() => {
          this.getPortfolios(userId, this.memberId);
        }, 350);
        setTimeout(() => {
          this.getDataForFirstPortfolio();
        }, 500);
      } else {
        this.dataSource.data = null;
        this.portfolios = null;
      }
    }, 200);
  }

  onChangeMember(memberId: number): void {
    this.memberId = memberId;
    localStorage.setItem('accountMemberId', memberId.toString());
    this.getPortfolios(this.userId, memberId);
    setTimeout(() => {
      this.getDataForFirstPortfolio();
    }, 200);

  }

  /**
   * Get date when change user or member
   */
  getDataForFirstPortfolio() {
    if (this.portfolios.length > 0) {
      this.portfolioId = this.portfolios[0].id;
      localStorage.setItem('accountPortfolioId', this.portfolioId.toString());
      this.getAccounts(this.portfolioId, this.keyword);
    } else {
      this.dataSource.data = null;
    }
    this.navigateRouter();
  }

  onChangePortfolio(portfolioId: number): void {
    this.portfolioId = portfolioId;
    localStorage.setItem('accountPortfolioId', portfolioId.toString());
    this.getAccounts(portfolioId, this.keyword);
    this.navigateRouter();
  }

  search(keyword: string): void {
    this.keyword = keyword.trim();
    this.getAccounts(this.portfolioId, this.keyword, 1);
    this.navigateRouter();
  }

  sortDataEvent(event): void {
    this.sortCol = event;
    this.sortData();
    this.iterator(this.data, 0);
    this.navigateRouter();
  }

  onPaginateChange(pageEvent): void {
    const page = pageEvent.pageIndex;
    this.navigateRouter(page);
  }

  navigateRouter(page = 0) {
    const queryParams = { sortBy: this.sortCol.active, sortType: this.sortCol.direction, keyword: null };
    if (this.keyword) {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    if (page === 0) {
      this.router.navigate(['/accounts'], {queryParams: queryParams}).then(() => {
      });
    } else {
      this.router.navigate(['accounts/page/' + (page + 1)], { queryParams: queryParams }).then(() => {});
    }
  }
}
