import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router:Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                if(this.router.url != '/login'){
                    this.authenticationService.logout();
                    //location.reload(true);
                }    
            }else if(err.status === 0 && err.statusText === 'Unknown Error'){
                this.authenticationService.logout();
            }
            var errorMessage = '';
            if(err.error.errors){
                Object.keys(err.error.errors).forEach(function(k, v){
                    errorMessage += err.error.errors[k] + "\n";
                });
            }else{
                errorMessage = err.error.message || err.statusText;
            }
            return throwError(errorMessage);
        }))
    }
}