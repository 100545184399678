import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators, Validator, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const checkFromTo: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  let from = control.get('from');
  let to = control.get('to');
  return (from && to && from.value != ""  && to.value != "" && from.value > to.value ) ? { 'fromTo': true} : null;
};

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent  implements OnInit {
  submitted: boolean;
  formBuilder: FormBuilder;
  dialogForm: FormGroup;
  constructor( public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  	this.formBuilder = new FormBuilder();
  }
  ngOnInit() {
  	this.dialogForm = this.formBuilder.group({
        from: ['', [Validators.maxLength(6)]],
        to: ['', [Validators.maxLength(6)]]
    });
    //this.dialogForm.setValidators(checkFromTo);
  }
  get f() { return this.dialogForm.controls; }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onConfirm(val):void {
    this.dialogRef.close(val);
  }
  onSubmit() {
  	this.submitted = true;
    if (this.dialogForm.invalid) {
        return;
    }
    let values = this.dialogForm.value;
    if(values.from == "" && values.to == ""){
      this.dialogForm.setErrors({'error':'You must enter at least one value'});
      return;
    }
    if(values.from != "" && values.to != "" && values.from >= values.to){
      this.dialogForm.setErrors({'error':'To must be greater than From','fieldErr':true});
      return;
    }
    this.dialogRef.close(values);
  }
  resetForm(){
     this.dialogForm.reset(this.data);
  }
}