import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketMetricsService } from '../_services/market-metrics.service';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../shared';

@Component({
  selector: 'data-providers',
  templateUrl: './data-providers.component.html',
  styleUrls: ['./data-providers.component.css']
})
export class DataProvidersComponent implements OnInit, OnDestroy {

  public products;
  public noProduct: boolean;
  private productSubscription;

  constructor (public marketMetrics: MarketMetricsService, private http: HttpClient) {}

  ngOnInit() {
    this.productSubscription = this.marketMetrics.getNewProducts().subscribe(products => {
      this.products = Object.values(products);
      if(this.products.length && this.products[0] !== 'New products record is empty') {
        this.noProduct = false;
      } else {
        this.noProduct = true;
      }
    });
  }
  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }

  convertToBase64(str) {
    return btoa(str);
  }
}

