import { Component, OnInit} from '@angular/core';
import { RoleService, PermissionService, AlertService, AuthenticationService } from '../_services';
import { Role } from '../_models/role';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Options, Globals } from '../shared';
declare var $: any;
@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {
  submitted: boolean;
  formBuilder: FormBuilder;
  roleForm: FormGroup;
  role  = new  Role();
  statusList:Options[] =[{'title':'Active','value':'1'},
	  	{'title':'Inactive','value':'0'}];
  permissions: string[] = [];
  groupPermissions: any;
  constructor(private roleService: RoleService, private permService: PermissionService, private router: Router, private route: ActivatedRoute, private alertService: AlertService, private globals: Globals) {
  	this.formBuilder = new FormBuilder();
  }
  get f() { return this.roleForm.controls; }

  ngOnInit() {
  	this.roleForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        status: ['', Validators.required],
    });

    this.permService.getPermissions().subscribe(data=>{
        this.groupPermissions = data;
    });
  }
  toggleVisibility(e){
    if( e.target.checked){
      if(e.target.value.indexOf('view') !== 0) {//check on add, edit, delete
        let viewEl = $(e.target).closest('div.col-xs-12').find('input:eq(0)');
        viewEl.prop('checked', true);
        viewEl.attr('disabled','disabled');
        if(this.permissions.indexOf(viewEl.val()) == -1){
          this.permissions.push(viewEl.val());
        }
      }
      this.permissions.push(e.target.value);
    }else{
      if(this.permissions.indexOf(e.target.value) > -1){
        this.permissions.splice(this.permissions.indexOf(e.target.value), 1);
        let viewEl = $(e.target).closest('div.col-xs-12').find('input:eq(0)');
        if(viewEl.prop('checked')){
          if($(e.target).closest('div.col-xs-12').find('input:checked').length == 1){
            viewEl.removeAttr('disabled');
          }
        }
      }
    }
  }
  onSubmit(role) {
  	this.submitted = true;
    if (this.roleForm.invalid) {
      window.scroll(0,0);
      return;
    }
    let values = this.roleForm.value;

    role.name = values.name;
    role.description = values.description;
    role.status = parseInt(values.status) || 0;
    this.globals.processing = true;
    this.roleService.addRole(role).subscribe(data => {
   		if(data && data.status == 'success'){
        if(this.permissions.length){
          this.roleService.givePermission({role:data.data.role.name,'permission': this.permissions.join(',')}).subscribe(data => {
            this.router.navigate(['roles']);
          });
        }else{
          this.router.navigate(['roles']);
        }
   		}
   });
  }

  onCancel() {
    this.router.navigate(['roles']);
  }

  resetForm(){
     this.submitted = false;
     this.roleForm.reset(new  Role());
     window.scroll(0,0);
  }
}
