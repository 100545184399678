﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { ExchangeComponent, EditExchangeComponent } from './exchange';
import { AdminComponent, UpdateAdminComponent } from './admin';
import { UserComponent, UpdateUserComponent } from './user';
import { RoleComponent, AddRoleComponent, EditRoleComponent } from './role';
import { CurrencyComponent, EditCurrencyComponent } from './currency';
import { FaqComponent, AddFaqComponent } from './faq';
import { ScriptsComponent } from './scripts-statistic';
import { PlanComponent, AddPlanComponent } from './plan';
import { AddMemberComponent, MemberComponent} from './member';
import { CrosscheckComponent } from './cross-check';
import { PortfolioComponent, AddPortfolioComponent } from './portfolio';
import {AccountComponent, AddAccountComponent} from './account';
import {DataProvidersComponent} from './data-providers';
import {DataInspectionComponent} from './data-inspection';
import {DataInspectionRosoComponent} from './data-inspection-roso';
import {DataInspectionScriptComponent} from './data-inspection-script';
import {CloneUserComponent} from './user/clone-user.component';
import {DataProvidersProductListComponent} from './data-providers/data-providers-productList.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },

    { path: 'exchanges', component: ExchangeComponent, canActivate: [AuthGuard], data: {permission: 'view xigniteExchanges'}},
    { path: 'exchanges/page/:page', component: ExchangeComponent, canActivate: [AuthGuard], data: {permission: 'view xigniteExchanges'},
      runGuardsAndResolvers: 'always' },
    { path: 'exchanges/:id', component: EditExchangeComponent, canActivate: [AuthGuard], data: {permission: 'view xigniteExchanges'}},

    { path: 'admins', component: AdminComponent, canActivate: [AuthGuard], data: {permission: 'view admins'}},
    { path: 'admins/page/:page', component: AdminComponent, canActivate: [AuthGuard], data: {permission: 'view admins'}},
    { path: 'admins/add', component: UpdateAdminComponent, canActivate: [AuthGuard], data: {permission: 'add admins'}},
    { path: 'admins/:id', component: UpdateAdminComponent, canActivate: [AuthGuard], data: {permission: 'view admins'}},

    { path: 'users', component: UserComponent, canActivate: [AuthGuard], data: {permission: 'view users'}},
    { path: 'users/page/:page', component: UserComponent, canActivate: [AuthGuard], data: {permission: 'view users'}},
    { path: 'users/add', component: UpdateUserComponent, canActivate: [AuthGuard], data: {permission: 'add users'}},
    { path: 'users/clone', component: CloneUserComponent, canActivate: [AuthGuard], data: {permission: 'add users'}},
    { path: 'users/:id', component: UpdateUserComponent, canActivate: [AuthGuard], data: {permission: 'view users'}},

    { path: 'roles', component: RoleComponent, canActivate: [AuthGuard], data: {permission: 'view roles'}},
    { path: 'roles/page/:page', component: RoleComponent, canActivate: [AuthGuard], data: {permission: 'view roles'}},
    { path: 'roles/add', component: AddRoleComponent, canActivate: [AuthGuard], data: {permission: 'add roles'}},
    { path: 'roles/:id', component: EditRoleComponent, canActivate: [AuthGuard], data: {permission: 'view roles'}},

    { path: 'currencies', component: CurrencyComponent, canActivate: [AuthGuard], data: {permission: 'view currencies'}},
    { path: 'currencies/page/:page', component: CurrencyComponent, canActivate: [AuthGuard], data: {permission: 'view currencies'}},
    { path: 'currencies/:id', component: EditCurrencyComponent, canActivate: [AuthGuard], data: {permission: 'view currencies'}},

    { path: 'faqs', component: FaqComponent, canActivate: [AuthGuard], data: {permission: 'view faqs'} },
    { path: 'faqs/add', component: AddFaqComponent, canActivate: [AuthGuard], data: {permission: 'add faqs'}},
    { path: 'faqs/:id', component: AddFaqComponent, canActivate: [AuthGuard], data: {permission: 'view faqs'}},

    { path: 'plans', component: PlanComponent, canActivate: [AuthGuard], data: {permission: 'view plans'}},
    { path: 'plans/page/:page', component: PlanComponent, canActivate: [AuthGuard], data: {permission: 'view plans'}},
    { path: 'plans/add', component: AddPlanComponent, canActivate: [AuthGuard], data: {permission: 'add plans'}},
    { path: 'plans/:id', component: AddPlanComponent, canActivate: [AuthGuard], data: {permission: 'view plans'}},

    { path: 'members', component: MemberComponent, canActivate: [AuthGuard], data: {permission: 'view members'}},
    { path: 'members/page/:page', component: MemberComponent, canActivate: [AuthGuard], data: {permission: 'view members'}},
    { path: 'members/add', component: AddMemberComponent, canActivate: [AuthGuard], data: {permission: 'add members'}},
    { path: 'members/:id', component: AddMemberComponent, canActivate: [AuthGuard], data: {permission: 'view members'}},

    { path: 'portfolios', component: PortfolioComponent, canActivate: [AuthGuard], data: {permission: 'view portfolios'}},
    { path: 'portfolios/page/:page', component: PortfolioComponent, canActivate: [AuthGuard], data: {permission: 'view portfolios'}},
    { path: 'portfolios/add', component: AddPortfolioComponent, canActivate: [AuthGuard], data: {permission: 'add portfolios'}},
    { path: 'portfolios/:id', component: AddPortfolioComponent, canActivate: [AuthGuard], data: {permission: 'view portfolios'}},

    { path: 'accounts', component: AccountComponent, canActivate: [AuthGuard], data: {permission: 'view accounts'}},
    { path: 'accounts/page/:page', component: AccountComponent, canActivate: [AuthGuard], data: {permission: 'view accounts'}},
    { path: 'accounts/add', component: AddAccountComponent, canActivate: [AuthGuard], data: {permission: 'add accounts'}},
    { path: 'accounts/:id', component: AddAccountComponent, canActivate: [AuthGuard], data: {permission: 'view accounts'}},

    { path: 'cross-check', component: CrosscheckComponent, canActivate: [AuthGuard], data: {permission: 'view members'}},

    // otherwise redirect to home
    { path: 'scripts', component: ScriptsComponent, canActivate: [AuthGuard], data: {} },
    { path: 'data-providers', component: DataProvidersComponent, canActivate: [AuthGuard], data: {} },
    { path: 'data-providers/type/:type/source/:source', component: DataProvidersProductListComponent, canActivate: [AuthGuard], data: {permission: 'add admins'}},
    { path: 'data-providers/type/:type/source/:source/:idii', component: DataProvidersProductListComponent, canActivate: [AuthGuard], data: {permission: 'add admins'}},
    { path: 'data-inspection', component: DataInspectionComponent, canActivate: [AuthGuard], data: {} },
    { path: 'data-inspection-roso', component: DataInspectionRosoComponent, canActivate: [AuthGuard], data: {} },
    { path: 'data-inspection-script', component: DataInspectionScriptComponent, canActivate: [AuthGuard], data: {} },
    { path: '**', redirectTo: '' }
];



@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class routing { }
