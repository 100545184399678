import {Component, ViewChild, AfterViewInit, OnInit, OnDestroy} from '@angular/core';
import {MatSort, MatTableDataSource, MatDialog, MatPaginatorModule, MatPaginator} from '@angular/material';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {tap, filter} from 'rxjs/operators';
import {RoleService} from '../_services';
import {Globals} from '../shared';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements AfterViewInit, OnInit, OnDestroy {
  reloadCom = false;
  navigationSubscription;
  displayedColumns: string[] = ['id', 'name', 'description', 'status'];
  dataSource = new MatTableDataSource();
  totalItems = 100;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  keyword: string;
  sortCol = {active: 'name', direction: 'asc'};
  // MatPaginator Output
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private roleService: RoleService, private route: ActivatedRoute, private router: Router, private globals: Globals) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      //.subscribe(({urlAfterRedirects}: NavigationEnd) => {
      .subscribe((e: any) => {
        this.initialiseInvites();
      });
  }

  initialiseInvites() {
    if (this.reloadCom) {
      this.loadComponent();
    }
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.sortBy) {
          this.sortCol.active = params.sortBy;
          this.sortCol.direction = params.sortType;
        }
      });
    this.loadComponent();
    this.reloadCom = true;
  }

  loadComponent() {
    const page = +this.route.snapshot.paramMap.get('page');
    this.route.queryParams.subscribe(params => {
      this.keyword = (params['keyword'] ? params['keyword'] : '');
    });
    this.getRoles(page, this.pageSize, this.keyword);
    //this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => {
        })
      )
      .subscribe();
  }

  search(keyword: string): void {
    this.keyword = keyword.trim();
    this.getRoles(1, this.pageSize, keyword);
  }

  getRoles(offset: number = 1, limit: number = 10, keyword: string = ''): void {
    this.roleService.getRoles(offset, limit, keyword, this.sortCol.active, this.sortCol.direction)
      .subscribe(roles => {
        this.totalItems = roles.pagination.total;
        this.dataSource.data = roles.roles;
        this.paginator.pageIndex = offset - 1;
      });
  }

  onPaginateChange(pageEvent): void {
    let currentPage = pageEvent.pageIndex + 1;
    this.getRoles(currentPage, pageEvent.pageSize, this.keyword);
    this.reloadCom = false;

    let queryParams = {sortBy: this.sortCol.active, sortType: this.sortCol.direction, keyword: null};
    if (this.keyword != '') {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    this.router.navigate(['roles/page/' + currentPage], {queryParams: queryParams}).then(() => {
      this.reloadCom = true;
    });
  }

  sortData(event): void {
    this.sortCol = event;
    let url = '/roles';
    if (this.paginator.pageIndex) {
      url += '/page/' + (this.paginator.pageIndex + 1);
      this.getRoles((this.paginator.pageIndex + 1), this.pageSize, this.keyword);
    } else {
      this.getRoles(1, this.pageSize, this.keyword);
    }
    let queryParams = {sortBy: event.active, sortType: event.direction, keyword: null};
    if (this.keyword != '') {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    this.reloadCom = false;
    this.router.navigate([url], {queryParams: queryParams}).then(() => {
      this.reloadCom = true;
    });
  }

}
