export class Portfolio {
  id: number;
  id_user: number;
  id_member: number;
  name: string;
  id_bank: number;
  id_currency: number;
  id_relationship: number;
  id_benchmark: number;
  transaction_fee_date: number;
  bank: Array<string>;
  currency: Array<string>;
  relationship: Array<string>;
  benchmark: Array<string>;
  has_cash: boolean;
  is_active: number;
  permissions: Array<string>;

}
