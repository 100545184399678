import {Component, OnInit} from '@angular/core';
import {MemberService} from '../_services/member.service';
import {CurrencyService, UserService} from '../_services';
import {Globals} from '../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Member} from '../_models/member';
import {GeneralUserComponent} from '../general-user.component';
import {Currency} from '../_models';
import {ProductService} from '../_services/product.service';
import {Product} from '../_models/product';
import {Profile} from '../_models/profile';
import {ProfileService} from '../_services/profile.service';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent extends GeneralUserComponent implements OnInit {
  submitted: boolean;
  formBuilder: FormBuilder;
  memberForm: FormGroup;
  member = new Member();
  backupMember = new Member();
  currencies: Currency[];
  products: Product[];
  profiles: Profile[];
  userId = 101;
  currencyId = 1;

  constructor(public userService: UserService, public memberService: MemberService, private currencyService: CurrencyService,
              private productService: ProductService, private profileService: ProfileService, private globals: Globals,
              private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    super(userService, memberService);
    this.formBuilder = new FormBuilder();
  }

  get f() { return this.memberForm.controls; }

  ngOnInit() {
    if (localStorage.getItem('userId')) {
      this.userId = +localStorage.getItem('userId');
    }
    this.memberForm = this.formBuilder.group({
      id_user: [this.userId, Validators.required],
      name_first: ['', Validators.required],
      id_profile: [null],
      id_currency: [this.currencyId],
      id_benchmark: [null],
      id_benchmark_addition: ['']
    });
    this.getMember();
    this.getAllUsers();
    this.getAllCurrencies();
    this.getProductsByUser();
    this.getProfilesByUser();
  }

  getMember(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.memberService.getMember(id)
        .subscribe(data => {
          this.getProductsByUser(data.member.id_user);
          this.getProfilesByUser(data.member.id_user);
          if (data.member.id_benchmark === 0) {
            data.member.id_benchmark = null;
          }
          if (data.member.id_profile === 0) {
            data.member.id_profile = null;
          }
          this.member = data.member;
          this.memberForm.reset(this.member);
          this.memberForm.get('id_user').disable();
          this.memberForm.get('id_currency').disable();
          this.backupMember = Object.assign({}, this.member);
        });
    } else {
      this.backupMember.id_user = this.userId;
      this.backupMember.id_currency = this.currencyId;
      this.backupMember.id_profile = null;
      this.backupMember.id_benchmark = null;
    }
  }

  getAllCurrencies(): void {
    this.currencyService.getAllCurrencies().subscribe(currencies => this.currencies = currencies.currencies);
  }

  getProductsByUser(userId: number = this.userId): void {
    this.productService.getByUser(userId).subscribe(products => this.products = products.products);
  }

  getProfilesByUser(userId: number = this.userId): void {
    this.profileService.getByUser(userId).subscribe(profiles => this.profiles = profiles.profiles);
  }

  onSubmit(member: Member): void {
    this.submitted = true;
    const values = this.memberForm.value;
    if (this.memberForm.invalid) {
      return;
    }
    this.globals.processing = true;
    if (member.id) {
      const data  = Object.assign({}, member);
      data.name_first = values.name_first;
      data.id_profile = values.id_profile;
      data.id_benchmark = values.id_benchmark;
      this.memberService.updateMember(data).subscribe(data => { this.getMember(); });
    } else {
      this.memberService.addMember(values).subscribe(data => {
        if (data && data.status === 'success') {
          this.backToList();
        }
      });
    }
  }

  onChangeUser(userId: number): void {
    localStorage.setItem('userId', userId.toString());
    this.memberForm.get('id_profile').setValue(0);
    this.memberForm.get('id_benchmark').setValue(0);
    this.getProductsByUser(userId);
    this.getProfilesByUser(userId);
  }

  backToList() {
    this.router.navigate(['members']);
  }

  resetForm() {
    this.submitted = false;
    this.memberForm.reset(this.backupMember);
  }

  openConfirmDialog(memberId): void {
    if (!memberId) { return; }
    const dialogRef = this.dialog.open(DialogComponent , {
      width: '350px',
      data: {type: true, message: 'Are you sure you want to delete this member?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.memberService.deleteMember(memberId).subscribe(data => {
          if (!data) {
            this.router.navigate(['members']);
          }
        });
      }
    });
  }

  focusBenchmarkAddition(benchmarkAddition: any, value): void {
  }

  focusoutBenchmarkAddition(benchmarkAddition: any, value): void {
    if (value > 100) { value = 100; }
    if (value < -100) { value = -100; }

    return;
  }
}
