import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService } from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private apiUrl = environment.apiUrl + '/permissions';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }

    getPermissions ( keyword:string = ''): Observable<any> {

        let params = new HttpParams({encoder: new CustomURLEncoder() });

        //let params = {limit:limit,page:offset,sortBy:'id',keyword: ''} ;
        if(keyword != ''){
          params = params.append('keyword', `${keyword}`);
        }
        return this.http.get<any>(this.apiUrl , {params})
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getPermissions', [])))
          );
    }
    getPermission(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getPermission Error')))
        );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          //this.alertService.error(`UserService: ${operation} failed: ${error}`);
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }

    /*getConditionalDataUsingPromise() {
    this.httpClient.get<Employee>(this.url).toPromise().then(data => {
      console.log('First Promise resolved.')
      if (data.id > 5) {
        let anotherUrl = 'http://dummy.restapiexample.com/api/v1/employee/23';
        this.httpClient.get<Employee>(anotherUrl).toPromise().then(data => {
          this.conditionalPromiseResult = data;
          console.log('Second Promise resolved.')
        });
      }
    });
  }*/
}
