import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {PlanService, AlertService} from '../_services';
import {Plan} from '../_models/plan';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSort, MatTableDataSource, MatPaginator} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Options, Globals} from '../shared';

declare function scrollUpDown(): any;

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.css']
})
export class AddPlanComponent implements OnInit, AfterViewInit {
  submitted = false;
  formBuilder: FormBuilder;
  planForm: FormGroup;
  plan = new Plan();
  backupPlan = new Plan();
  id = 0;
  frequencies: Options[] = [
    {'title': 'Year', 'value': 'year'},
    {'title': 'Month', 'value': 'month'}
  ];
  statusList: Options[] = [
    {'title': 'Active', 'value': '1'},
    {'title': 'Inactive', 'value': '0'},
  ];
  displayedColumns: string[] = ['id', 'name', 'plan_start', 'plan_end'];
  dataSource = new MatTableDataSource();
  totalItems = 100;
  pageSize = 10;
  sortCol = {active: 'name', direction: 'asc'};
  // MatPaginator Output
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private planService: PlanService, private router: Router, private route: ActivatedRoute, private alertService: AlertService,
              private globals: Globals) {
    this.formBuilder = new FormBuilder();
  }

  get f() {
    return this.planForm.controls;
  }

  ngOnInit() {
    setTimeout(() => {
      scrollUpDown();
    }, 500);
    this.planForm = this.formBuilder.group({
      name: ['', Validators.required],
      max_member: ['', Validators.required],
      max_portfolio: ['', Validators.required],
      max_account: ['', Validators.required],
      max_product: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
      is_public: [''],
      frequency: [''],
      frequency_interval: [''],
      method: [''],
      status: [''],
      cycles: [''],
      trial_frequency: [''],
      trial_cycles: [''],
      setup_fee: [''],
      tax: ['']
    });
    this.getPlan();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => {
        })
      )
      .subscribe();
  }

  getPlan(): void {
    this.id = +this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.planService.getPlan(this.id)
        .subscribe(data => {
          this.plan = data;
          this.plan.method = '1';
          this.planForm.reset(data);
          if (this.plan.max_member === -1) {
            this.planForm.get('max_member').disable();
          } else {
            this.planForm.get('max_member').enable();
          }
          if (this.plan.max_portfolio === -1) {
            this.planForm.get('max_portfolio').disable();
          } else {
            this.planForm.get('max_portfolio').enable();
          }
          if (this.plan.max_account === -1) {
            this.planForm.get('max_account').disable();
          } else {
            this.planForm.get('max_account').enable();
          }
          if (this.plan.max_product === -1) {
            this.planForm.get('max_product').disable();
          } else {
            this.planForm.get('max_product').enable();
          }
          this.backupPlan = Object.assign({}, this.plan);
        });
      this.planService.getUserByPlan(this.id)
        .subscribe(data => {
          this.dataSource.data = data.users;
          this.totalItems = data.pagination.total;
        });
    } else {
      this.planForm.get('max_member').enable();
      this.planForm.get('max_portfolio').enable();
      this.planForm.get('max_account').enable();
      this.planForm.get('max_product').enable();
      this.planForm.reset(this.plan);
    }
  }

  onSubmit(plan) {
    this.submitted = true;
    if (this.planForm.invalid) {
      return;
    }
    this.submitted = false;
    const values = this.planForm.value;
    let data = Object.assign({}, plan);
    data.max_member = -1;
    data.max_account = -1;
    data.max_portfolio = -1;
    data.max_product = -1;
    data = Object.assign(data, values);
    this.globals.processing = true;
    delete data.method;
    if (data.id) {
      this.planService.updatePlan(data).subscribe(data => {
        this.getPlan();
      });
    } else {
      this.planService.addPlan(data).subscribe(data => {
        if (data && data.status === 'success') {
          this.backToList();
        }
      });
    }
  }

  // activePlan() {
  //   this.globals.processing = true;
  //   this.planService.updatePaypalState(this.id, 'ACTIVE').subscribe(data => {
  //     if (data && data.status === 'success') {
  //       this.plan.paypal_status = 'ACTIVE';
  //     }
  //   });
  // }

  unLimited(el, elName) {
    if ((el.target.checked)) {
      this.planForm.get(elName).setValue(-1);
      this.planForm.get(elName).disable();
    } else {
      this.planForm.get(elName).setValue('');
      this.planForm.get(elName).enable();
    }
  }

  // showActivePlan() {
  //   if (this.id && this.plan.paypal_plan != null && this.plan.paypal_status !== 'ACTIVE') {
  //     return true;
  //   }
  //   return false;
  // }

  updateValidate(value) {
    if (value) {
      this.planForm.get('trial_cycles').setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.planForm.get('trial_cycles').clearValidators();
    }
    this.planForm.get('trial_cycles').updateValueAndValidity();
    this.planForm.updateValueAndValidity();
  }

  resetForm() {
    this.submitted = false;
    this.sortCol = {active: 'name', direction: 'asc'};
    this.getPlan();
  }

  backToList() {
    this.router.navigate(['plans']);
  }

  onPaginateChange(pageEvent): void {
    const currentPage = pageEvent.pageIndex + 1;
    this.planService.getUserByPlan(this.id, currentPage, this.sortCol.active, this.sortCol.direction).subscribe(list => {
      this.dataSource.data = list.users;
    });
  }

  sortData(event): void {
    this.sortCol = event;
    this.planService.getUserByPlan(this.id, this.paginator.pageIndex, event.active, event.direction)
      .subscribe(data => {
        this.dataSource.data = data.users;
        this.totalItems = data.pagination.total;
      });
  }
}
