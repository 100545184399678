import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AlertService} from './alert.service';
import {Observable, of} from 'rxjs';
import {CustomURLEncoder, httpOptions} from '../_helpers/http';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private apiUrl = environment.apiUrl + '/accounts';  // URL to web api

  constructor(private http: HttpClient, private alertService: AlertService) {}

  getAccounts (portfolioId: number, keyword: string = '', offset: number = 0, limit: number = 200, sortBy: string = 'account_number',
               sortType: string = 'asc'): Observable<any> {
    let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `${limit}`).set('page', `${offset}`);
    params = params.append('filter[id_portfolio]', `${portfolioId}`);
    params = params.append('sortBy', `${sortBy}`);
    params = params.append('sortType', `${sortType}`);
    return this.http.get<any>(this.apiUrl , {params})
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('getAccount', [])))
      );
  }

  getAccountsByMemberId(memberId: number): Observable<any> {
    let params = new HttpParams({encoder: new CustomURLEncoder() });
    params = params.append('filter[id_member]', `${memberId}`);
    params = params.append('sortBy', 'id');
    params = params.append('sortType', 'asc');
    return this.http.get<any>(this.apiUrl, {params})
      .pipe(
        map(response => {
          if (response && response.status === 'success') {
            return response.data;
          }
          return [];
        },
        catchError(this.handleError('getAccount', [])))
      );
  }

  getAccount(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + id).pipe(
      map(response => {
          if (response && response.status === 'success') {
            return response.data;
          }
          return [];
        },
        catchError(this.handleError<any>('getAccount Error')))
    );
  }

  getByProductId(productId: number, memberId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/product/' + productId + '/' + memberId)
      .pipe(
        map(response => {
          if (response && response.status === 'success') {
            return response.data;
          }
          return [];
        },
        catchError(this.handleError('getAccount', [])))
      )
  }

  addAccount(item: any): Observable<any> {
    return this.http.post<Account>(this.apiUrl, item, httpOptions).pipe(
      tap(_ => this.alertService.success(`The account has been added`, true)),
      catchError(this.handleError<any>('addAccount'))
    );
  }

  updateAccount (item: any): Observable<any> {
    return this.http.put(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
      tap(() => this.alertService.success(`The account has been updated`)),
      catchError(this.handleError<any>('updateAccount'))
    );
  }

  deleteAccount(id: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/' + id).pipe(
      map(_ => {
        this.alertService.success(`The account has been deleted`, true);
        return null;
      }),
      catchError(this.handleError<any>('deleteAccount Error', [false]))
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.alertService.error(`${error}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
