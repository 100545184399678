import { Component, ViewChild, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ExchangeService, AmountService, AlertService, AuthenticationService } from '../_services';
import { Exchange } from '../_models/exchange';
import { MatSort, MatTableDataSource, MatDialog, MatPaginatorModule, MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Options } from '../shared/option';
import { DialogComponent } from '../dialog/dialog.component';
import { Globals } from '../shared';

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'exponentialStrength'})
export class ExponentialStrengthPipe implements PipeTransform {
  transform(value:string):string {
    value = value.toString();
    if(/^\-\d+$/.test(value)){
      return "<= " + value.replace("-","");
    }
    if(/^\d+\-$/.test(value)){
      return "> " + value.replace("-","");
    }
    if(/^\d+\-\d+$/.test(value)){
      return "> " + value.replace("-"," && <= ");
    }
    if(/^\-\d+\-(\-)?\d+$/.test(value)){
      return "> " + value.replace(/(\d+)\-/g,'$1 && <= ');
    }
    return value;
  }
}

export class Tax {
    id: number = 0;
    id_exchange: number = 0;
    type: string = 'percentage';
    activity: number = 1;
    activity_text: string = '';
    amount_range: string = '1000-';
    value: number = 0;
    data: any = null;
}
export function ValidateUrl(control: AbstractControl) {
  if (!control.value.startsWith('https') || !control.value.includes('.io')) {
    return { validUrl: true };
  }
  return null;
}
@Component({
  selector: 'app-edit-exchange',
  templateUrl: './edit-exchange.component.html',
  styleUrls: ['./edit-exchange.component.css']
})

export class EditExchangeComponent implements OnInit  {
  processing: boolean = false;
  submitted: boolean;
  formBuilder: FormBuilder;
  exchangeForm: FormGroup;
  taxRuleForm: FormGroup;
  taxRuleEditing: boolean = false;

  displayedColumns: string[] =  ['type','activity_text','amount_range','value'];
  dataSource = new MatTableDataSource();
  exchange = new Exchange();
  backup: any;
  settlementDateList = [0,1,2,3,4,5,6,7,8,9,10];
  priorityList = [1,2,3,4,5];
  statusList = [{
      'value': 1,
      'text': 'Active'
    }, {
      'value': 0,
      'text': 'Inactive'
    }
  ];
  formTaxRuleTitle = 'Add Tax Rule';
  tax = new Tax();
  backupTax = new Tax();
  typeList: Options[] = [
    {value: 'percentage', title: 'Percentage'},
    {value: 'amount', title: 'Amount'}
  ];
  amount_range: [{label:any,value:any}];
  activity_list:Options[] =[{'title':'Buy','value':'1'},
	  	{'title':'Sell','value':'6'},
	  	{'title':'Coupon','value':'2'},
	  	{'title':'Dividend','value':'3'},
	  	{'title':'Interest','value':'4'},
	  	{'title':'Add','value':'0'},
	  	{'title':'Remove','value':'5'},
	  	{'title':'Return on Capital','value':'12'}];
  sortCol  = {active: "type", direction: "asc"};
  @ViewChild(MatSort) sort: MatSort;

  constructor(private exchangeService: ExchangeService, private amountService: AmountService, private router: Router, private route: ActivatedRoute, private alertService: AlertService,  public dialog: MatDialog, public globals: Globals) {
  	this.formBuilder = new FormBuilder();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent , {
      width: '350px',
      data: {from: '', to: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        let data: any = {};
        if(result.from && result.to){
          data.from = result.from;
          data.to = result.to;
        }else if(result.from){
          data.from = result.from;
        }else{
          data.to = result.to;
        }
        this.amountService.addAmountRange(data).subscribe(data => {
          this.getAmountRanges();
        });

      }
    });
  }
  openConfirmDialog(item, type = '', params = null): void {
    if(!item) return;
    let confirmMessage = 'Are you sure you want to delete this item?';
    switch (type) {
          case "taxrule":
            confirmMessage = 'Are you sure you want to delete this tax rule?';
            break;
        }

    const dialogRef = this.dialog.open(DialogComponent , {
      width: '350px',
      data: {type:true,message:confirmMessage}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        switch (type) {
          case "taxrule":
            this.deleteTaxRule(item, params);
            break;
          default:
            this.amountService.deleteAmountRange(item).subscribe(data => {
              this.getAmountRanges();
            });
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.getAmountRanges();
  	this.getExchange();
  	this.dataSource.sort = this.sort;
  	this.exchangeForm = this.formBuilder.group({
            pr_name: ['', Validators.required],
            settlement_date_add: ['', Validators.required],
            priority: ['', Validators.required],
            status: ['', Validators.required]
        });
  	this.taxRuleForm = this.formBuilder.group({
            type: ['', Validators.required],
            activity: ['', Validators.required],
            amount_range: [],
            value: ['', Validators.required]
        });
    this.taxRuleForm.reset(this.tax);
  	this.taxRuleForm.get('type').valueChanges
	    .subscribe(selectedType => {
	        if (selectedType == 'percentage') {
	        	this.taxRuleForm.get('amount_range').clearValidators();
	        }
	        else {
	          this.taxRuleForm.get('amount_range').setValidators([Validators.required]);
	        }
	        this.taxRuleForm.get('amount_range').updateValueAndValidity();
	        this.taxRuleForm.updateValueAndValidity();
	    });
  }

  get f() { return this.exchangeForm.controls; }
  get fTR() { return this.taxRuleForm.controls; }

  getAmountRanges() : void {
    this.amountService.getAmountRanges().subscribe(data => {
        this.amount_range = data.amount_range_history;
        if(this.amount_range.length){
          this.tax.amount_range = this.amount_range[0].value;
          this.taxRuleForm.get('amount_range').setValue(this.tax.amount_range);
        }
      });
  }
  getExchange(): void {
    this.submitted = false;
  	const id = +this.route.snapshot.paramMap.get('id');
    this.exchangeService.getExchange(id)
      .subscribe(data => {
        this.exchange = data.xignite_exchange;
        this.exchangeForm.reset(this.exchange);
        this.backup = Object.assign({}, this.exchange);
        this.dataSource.data = data.tax_rules;
      });
  }
	onSubmit(data) {
      this.submitted = true;
      if (this.exchangeForm.invalid) {
          return;
      }
      let values = this.exchangeForm.value;
      data.pr_name = values.pr_name;
      data.settlement_date = values.settlement_date_add;
      data.priority = values.priority;
      data.status = values.status;
      this.globals.processing = true;
      this.exchangeService.updateExchange(data).subscribe(exchange => {});
  }
  onSubmitTaxRule(tax) {
      tax.id_exchange = this.exchange.id;
      this.submitted = true;
      if (this.taxRuleForm.invalid) {
          return;
      }

      const params =  Object.assign({}, tax);
      const values = this.taxRuleForm.value;
      params.value = values.value;
      if (params.type === 'amount') {
        let rules = [];
        if (params.data) {
          if (params.item) {
            params.data.forEach((value, index)  => {
              rules[index] = value;
              if (value.range === params.item.range && value.value === params.item.value){
                rules[index].range = values.amount_range;
                rules[index].value = values.value;
              }
            });
          } else {
            rules = params.data;
            rules.push({'range': values.amount_range, 'value': values.value});
          }
        } else {
          rules.push({'range': values.amount_range, 'value': values.value});
        }
        params.data = rules;
        params.value = 0;
      }
      this.globals.processing = true;
      if(params.id){
         this.exchangeService.updateTaxRule(params).subscribe(data => {
           if(data){
              this.getExchange();
              this.taxRuleEditing = false;
           }
         });
      }else{
        delete params.id;
        params.activity = values.activity;
        this.exchangeService.addTaxRule(params).subscribe(data => {
          if(data){
            this.getExchange();
            this.taxRuleEditing = false;
          }
        });
      }
  }
  updateActivityText(e){
    if(this.tax.id){
      this.tax.activity_text = e.target.options[e.target.selectedIndex].text;
    }
  }
  disabledControl():void{
    this.taxRuleEditing = true;
    if(this.tax.id){//&& this.tax.type == 'amount'
      this.taxRuleForm.get('type').disable();
      this.taxRuleForm.get('activity').disable();
    }else{
      this.taxRuleForm.get('type').enable();
      this.taxRuleForm.get('activity').enable();
    }
  }
  addTaxRule():void {
    this.formTaxRuleTitle = 'Add Tax Rule';
    this.tax = new Tax();
    this.tax.amount_range = this.amount_range[0].value;
    this.taxRuleForm.reset(this.tax);
    this.backupTax = Object.assign({}, this.tax);
    this.disabledControl();
  }
  editTaxRule(tax,item = null):void {
    this.submitted = false;
    this.formTaxRuleTitle = 'Edit Tax Rule';
    if(item){
      tax.item = item;
      tax.value = item.value;
      let that = this.amount_range;
      let temp = from(this.amount_range).pipe(filter(data => (data.value == item.range)));
      let found = false;
      temp.subscribe({
       next(response) {
        found = true;
        tax.amount_range = response.value;
       },
       complete() {
         if(!found){
            let tem = new ExponentialStrengthPipe();
            that.push({label: tem.transform(item.range), value: item.range});
            tax.amount_range = item.range;
         }
       }
      });
    }
    this.tax = tax;
    this.taxRuleForm.reset(tax);
    this.backupTax = Object.assign({}, tax);
    this.disabledControl();
  }
  deleteTaxRule(tax, item=null){
    if(item){
      let params = Object.assign({}, tax);
      if(params.data && params.data.length > 1){
        let rules = [];
        params.data.forEach((value, index)  => {
          if(!(value.range == item.range && value.value == item.value)){
            rules.push(value);
          }
        });
        params.data = {'rules':rules};
        this.exchangeService.updateTaxRule(params).subscribe(data => {this.getExchange();});
        return;
      }
    }
    this.exchangeService.deleteTaxRule(tax.id).subscribe(data => {
      if(this.taxRuleEditing && this.tax.id == tax.id){
        this.addTaxRule();
      }
      this.getExchange();
    });
  }
  backToList(){
     this.router.navigate(['exchanges']);
  }
  cancelTaxRule(){
    this.taxRuleEditing = false;
  }
  resetExchange(){
    this.exchangeForm.reset(this.backup);
  }
  resetTaxRule(){
    this.tax.type = this.backupTax.type;
    this.taxRuleForm.reset(this.backupTax);
  }
}

