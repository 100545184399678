import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AlertService} from './alert.service';
import {Observable, of} from 'rxjs';
import {CustomURLEncoder, httpOptions} from '../_helpers/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Portfolio} from '../_models/portfolio';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  private apiUrl = environment.apiUrl + '/portfolios';  // URL to web api

  constructor(private http: HttpClient, private alertService: AlertService) { }

  getPortfolios(offset: number = 0, limit: number = 10, keyword: string, userId: number = 0, memberId: number = 0, sortBy: string = 'name',
             sortType: string = 'asc'): Observable<any> {
    let params = new HttpParams({encoder: new CustomURLEncoder()}).set('limit', `${limit}`).set('page', `${offset}`);
    if (keyword) {
      params = params.append('filter[name]', `${keyword}`);
    }
    if (userId !== 0) {
      params = params.append('filter[id_user]', `${userId}`);
    }
    if (memberId !== 0) {
      params = params.append('filter[id_member]', `${memberId}`);
    }
    params = params.append('sortBy', `${sortBy}`);
    params = params.append('sortType', `${sortType}`);
    return this.http.get<any>(this.apiUrl, {params})
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('getPortfolios', [])))
      );
  }

  getPortfolio(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + id).pipe(
      map(response => {
          if (response && response.status === 'success') {
            return response.data;
          }
          return [];
        },
        catchError(this.handleError<any>('getPortfolio', [])))
    );
  }

  addPortfolio(item: any): Observable<any> {
    return this.http.post<Portfolio>(this.apiUrl, item, httpOptions).pipe(
      tap(_ => this.alertService.success(`The portfolio has been added.`, true)),
      catchError(this.handleError<any>('addPortfolio'))
    );
  }

  updatePortfolio(item: Portfolio): Observable<any> {
    return this.http.patch(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
      tap(_ => this.alertService.success(`The portfolio has been updated.`)),
      catchError(this.handleError<any>('updatePortfolio', []))
    );
  }

  deletePortfolio(id: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/' + id).pipe(
      map(_ => {
        this.alertService.success(`The portfolio has been deleted.`, true);
        return null;
      }),
      catchError(this.handleError<any>('deletePortfolio Error', [false]))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.alertService.error(`${error}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
