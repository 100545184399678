import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../_services';

import { FormControl, FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {environment} from '../../environments/environment';


declare function makeDatePicker(): any;

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'DD-MMM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export const validation: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	let from = control.get('date_from');
	let to = control.get('date_to');
	let id_account = control.get('id_account');
	return (from && to && id_account && from.value != ""  && to.value != "" && from.value >= to.value) ? { 'validation': true} : null;
};

@Component({
	selector: 'data-inpsection-roso',
	templateUrl: './data-inspection-roso.component.html',
	styleUrls: ['./data-inspection-roso.component.css'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		  },

		  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	]
})

export class DataInspectionRosoComponent implements OnInit {
	public GIAForm: FormGroup;
	public formBuilder: FormBuilder;

	public userId = 101;

	users = [];
	loading = false;
	isError = false;

	betaLink = '';
	LiveLink = '';
  link = '';
  hidden = true;

	constructor( public userService: UserService){
		this.formBuilder = new FormBuilder();
	}
	ngOnInit() {

		this.loadComponent();
		this.loadScript();

	}

	ngOnDestroy() {
	}

	loadScript() {
		let script = document.createElement('script');
		script.innerHTML = '';
		script.src = 'assets/js/jquery-ui.js';
		script.async = true;
		script.defer = true;
		document.getElementsByTagName('head')[0].appendChild(script);

		let element = document.createElement('link');
		element.rel = 'stylesheet';
		element.href = 'assets/css/jquery-ui.css';
		document.getElementsByTagName('head')[0].appendChild(element);
		setTimeout(() => {
			makeDatePicker();
		}, 1000);
	}

	loadComponent() {
		this.getAllUsers();
	}


	onChangeUser(userId: number): void {
    this.userId = userId;

    const us = this.users.filter( item => item.id == userId )[0];

    this.hidden = false;

    this.betaLink = environment.PRBETAURL + '/login/roso/' + us.usernamef ;

    this.LiveLink = environment.PRURL + '/login/roso/' + us.usernamef ;
	}

	asIsOrder(a, b) {
		return 1;
	}


	getAllUsers(): void {
		this.userService.getAllInspectableUser('1').subscribe(users => {
			this.users = users;
			let hasUser101 = false;
			for (let i = 0; i < users.length; i++) {
				if (users[i]['id'] == 101) {
					hasUser101 = true;
					break;
				}
			}
			if (!hasUser101) {
				this.userId = users[0]['id'];
			}

		});
	}



	generateCodeAndGoToPage(code: number) {
    this.loading = true;
    this.isError = false;
    this.userService.sendCodeToMobile(this.userId).subscribe( data => {
        this.loading = false;
        if(code === 0) {
            this.link = this.betaLink;
        }
        else {
          this.link = this.LiveLink;
        }
      const element = document.createElement('a');
      element.target = '_blank';
      element.href = this.link;
      element.click();
      element.remove();
    }, error => {
        this.loading = false;
        this.isError = true;
    });
  }

}
