import { Component, OnInit } from '@angular/core';
import { MemberService } from '../_services/member.service';
import {Member, User} from '../_models';
import { UserService } from '../_services';
import { AccountService } from '../_services/account.service';
import { CrosscheckService } from '../_services/crosscheck.service';
import * as _moment from 'moment';
const moment =  _moment;

@Component({
  selector: 'app-crosscheck',
  templateUrl: './crosscheck.component.html',
  styleUrls: ['./crosscheck.component.css']
})
export class CrosscheckComponent implements OnInit {
  reloadCom = false;
  memberId;
  userId;
  dataCash;
  dataUcgRcg;
  dataPerformance;
  dataPerformanceContribution;
  dataDriverPosition;
  dataGia;
  members: Member[];
  users: User[];
  today;
  memberYTD;

  public selectedAccounts = [];
  public accounts = [];
  public isFilterMember = true;

  constructor(public userService: UserService, private crosscheckService: CrosscheckService, public memberService: MemberService, public accountService: AccountService) {}

  ngOnInit() {
    this.today = moment().format('DD-MMM-YYYY');
    this.loadUserSelected();
    this.getAllUsers();
    this.loadMembers().subscribe(members => {
      this.members = members.members;
      this.loadMemberSelected();
      this.getAccountsByMemberId(this.memberId);
      this.loadDataResponse();
      this.getSelectedMemberYTD();
    });
  }

  getSelectedMemberYTD() {
    let selectedMember;
    for (let i of this.members) {
      if (i['id'] == this.memberId) {
        selectedMember = i;
      }
    }
    this.memberYTD = this.parseDateToDisplay(selectedMember['year_start_date']);
  }

  parseDateToDisplay(dateInput) {
    let dateResult;
    if (typeof(dateInput) === 'undefined') {
      dateResult = moment().format('DD-MMM-YYYY');
    } else {
      let dateInputArr = dateInput.split('-');
      dateResult = moment().date(dateInputArr[2]).month(parseInt(dateInputArr[1]) - 1).year(dateInputArr[0]).format('DD-MMM-YYYY');
    }
    return dateResult;
  }

  getAllUsers(): void {
    this.userService.getAllUsers('username', 'asc', '1')
      .subscribe(users => {
        this.users = users.users;
      });
  }

  loadMembers = () => this.memberService.getInspectionMembers(this.userId);

  loadUserSelected = () => {
      if (sessionStorage.getItem('accountUserId')) {
        this.userId = sessionStorage.getItem('accountUserId');
      } else {
        this.userId = 101;
      }
  }
  loadMemberSelected = () => {
    if (sessionStorage.getItem('memberId')) {
      this.memberId = sessionStorage.getItem('memberId');
    }

    if(!this.memberId) {
      this.memberId = this.members[0].id;
    }
  }

  onChangeUser(userId: number): void {
    this.userId = userId;
    sessionStorage.setItem('accountUserId', userId.toString());
    this.loadMembers().subscribe(members => {
      this.members = members.members;
      this.memberId = this.members[0].id.toString();
      sessionStorage.setItem('memberId', this.memberId);
      this.onChange(this.memberId);
    });
  }

  getAccountsByMemberId(memberId: number): void {
    this.accountService.getAccountsByMemberId(memberId).subscribe(accounts => {
      this.accounts = accounts.accounts;
    });
  }

  initialiseInvites() {
    if (this.reloadCom) {
      this.loadDataResponse()
    }
  }

  onChange(memberId: number): void {
    this.memberId = memberId;
    sessionStorage.setItem('memberId', memberId.toString());
    this.getAccountsByMemberId(this.memberId);
    this.selectedAccounts = [];
    this.loadDataResponse();
    this.getSelectedMemberYTD();
  }

  onChangeAccoutns(newValue) {
    this.loadDataResponse()
  }

  loadDataResponse = () => {
    this.loadDataCash();
    this.loadDataUcgRcg();
    this.loadDataPerformance();
    this.loadDataPerformanceContribution();
    this.loadDataDriverPosition();
    this.loadDataGia();
  }

  formatDataCash = (dataList) => {
    if(this.selectedAccounts.length === 0) {
      return dataList;
    }

    return dataList.filter( row => this.selectedAccounts.indexOf(row.id) !== -1 );
  }

  loadDataCash = () => {
    this.crosscheckService.Cash(this.memberId, this.selectedAccounts.toString()).subscribe(response => {
      this.dataCash = this.formatDataCash(response.crosscheck);
    });
  }

  loadDataUcgRcg = () => {
    this.crosscheckService.UcgRcg(this.memberId, this.selectedAccounts.toString()).subscribe(response => {
      this.isFilterMember = response.isFilterMember;
      this.dataUcgRcg = [response.crosscheck];
    });
  }

  loadDataPerformance = () => {
    this.crosscheckService.Performance(this.memberId, this.selectedAccounts.toString()).subscribe(response => {
      this.dataPerformance = [response.crosscheck];
    });
  }

  loadDataPerformanceContribution = () => {
    this.crosscheckService.PerformanceContribution(this.memberId, this.selectedAccounts.toString()).subscribe(response => {
      this.dataPerformanceContribution = [response.crosscheck];
    });
  }

  loadDataDriverPosition = () => {
    this.crosscheckService.DriverPosition(this.memberId, this.selectedAccounts.toString()).subscribe(response => {
      this.dataDriverPosition = [response.crosscheck];
    });
  }

  loadDataGia = () => {
    this.crosscheckService.Gia(this.memberId, this.selectedAccounts.toString()).subscribe(response => {
      this.dataGia = response.crosscheck;
    });
  }
}
