import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PortfolioService} from '../_services/portfolio.service';
import {UserService} from '../_services';
import {GeneralUserComponent} from '../general-user.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Globals} from '../shared';
import {MemberService} from '../_services/member.service';
import {filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent extends GeneralUserComponent implements AfterViewInit, OnInit, OnDestroy {

  navigationSubscription;
  reloadCom = false;
  keyword: string;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] =  ['name', 'bank', 'benchmark', 'currency', 'relationship', 'has_cash', 'transaction_fee_date',
                                'is_active'];
  totalItems = 100;
  pageSize = 10;
  userId = 101;
  memberId = 105;
  sortCol  = {active: 'name', direction: 'asc'};
  // MatPaginator Output
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public userService: UserService, public memberService: MemberService, private portfolioService: PortfolioService,
              private route: ActivatedRoute, private globals: Globals, private router: Router) {
    super(userService, memberService);
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(()  => {
        this.initialiseInvites();
      });
  }

  ngOnInit() {
    this.loadComponent();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => {})
      )
      .subscribe();
  }

  ngOnDestroy() {
    /**
     * avoid memory leaks here by cleaning up after ourselves. If we
     * don't then we will continue to run our initialiseInvites()
     * method on every navigationEnd event.
     */
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  initialiseInvites() {
    if (this.reloadCom) {
      this.loadComponent();
    }
  }

  loadComponent() {
    const page = +this.route.snapshot.paramMap.get('page');
    if (localStorage.getItem('portfolioUserId')) {
      this.userId = +localStorage.getItem('portfolioUserId');
    }
    if (localStorage.getItem('portfolioMemberId')) {
      this.memberId = +localStorage.getItem('portfolioMemberId');
    }
    this.route.queryParams.subscribe(params => {
      this.keyword = (params['keyword'] ? params['keyword'] : '');
      this.sortCol.active = (params['sortBy'] ? params['sortBy'] : 'name');
      this.sortCol.direction = (params['sortType'] ? params['sortType'] : 'asc');
    });
    this.getAllUsers();
    this.getMembersByUser(this.userId);
    this.getPortfolios(page, this.pageSize, this.userId, this.memberId, this.keyword);
  }

  getPortfolios(offset: number = 1, limit: number = 10, userId: number, memberId: number, keyword: string = ''): void {
    this.portfolioService.getPortfolios(offset, limit, keyword, userId, memberId, this.sortCol.active, this.sortCol.direction)
      .subscribe(portfolios => {
        this.totalItems = portfolios.pagination.total;
        this.dataSource.data = portfolios.portfolios;
        this.paginator.pageIndex = offset - 1;
      });
  }

  onChangeUser(userId: number): void {
    this.userId = userId;
    localStorage.setItem('portfolioUserId', userId.toString());
    this.getMembersByUser(userId);
    setTimeout(() => {
      if (this.members.length > 0) {
        this.memberId = this.members[0].id;
        localStorage.setItem('portfolioMemberId', this.memberId.toString());
        this.getPortfolios(1, this.pageSize, this.userId, this.memberId, this.keyword);
      } else {
        this.dataSource.data = null;
      }
    }, 200);
  }

  onChangeMember(memberId: number): void {
    this.memberId = memberId;
    localStorage.setItem('portfolioMemberId', memberId.toString());
    this.getPortfolios(1, this.pageSize, this.userId, memberId, this.keyword);
  }

  search(keyword: string): void {
    this.keyword = keyword.trim();
    this.getPortfolios(1, this.pageSize, this.userId, this.memberId, this.keyword);
  }

  sortData(event): void {
    let url = '/portfolios';
    this.sortCol = event;
    if (this.paginator.pageIndex) {
      url += '/page/' + (this.paginator.pageIndex + 1);
      this.getPortfolios((this.paginator.pageIndex + 1), this.pageSize, this.userId, this.memberId, this.keyword);
    } else {
      this.getPortfolios(1, this.pageSize, this.userId, this.memberId, this.keyword);
    }
    const queryParams = { sortBy: event.active, sortType: event.direction, keyword: null};
    if (this.keyword) {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    this.reloadCom = false;
    this.router.navigate([url], { queryParams: queryParams }).then(() => {
      this.reloadCom = true;
    });
  }

  onPaginateChange(pageEvent): void {
    const portfolioPage = pageEvent.pageIndex + 1;
    this.getPortfolios(portfolioPage, pageEvent.pageSize, this.userId, this.memberId, this.keyword);
    this.reloadCom = false;
    const queryParams = { sortBy: this.sortCol.active, sortType: this.sortCol.direction, keyword: null};
    if (this.keyword) {
      queryParams.keyword = this.keyword;
    } else {
      delete queryParams.keyword;
    }
    this.router.navigate(['portfolios/page/' + portfolioPage], { queryParams: queryParams }).then(() => {
      this.reloadCom = true;
    });
  }
}
