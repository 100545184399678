import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Faq } from '../_models';
import { AlertService } from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private apiUrl = environment.apiUrl + '/faqs';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }
    getTopics(): Observable<any>{
      return this.http.get<any>(this.apiUrl + '/showTopicList')
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getTopic', [])))
          );
    }
    getFaqs (id: number = 0, page: number = 0): Observable<any> {
      let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `1000`);
      if(page){
        params = params.append('page', `${page}`);
      }

      return this.http.get<any>(this.apiUrl + '/showByTopic/' + id,{params})
        .pipe(
            map(response => {
              if (response && response.status == 'success') {
                  return response.data;
              }
              return [];
          },
          catchError(this.handleError('getFaq', [])))
        );
    }
    getFaq(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getFaq Error')))
        );
    }
    addFaq (item: any): Observable<any> {
        return this.http.post<Faq>(this.apiUrl, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The faq has been added`,true)),
          catchError(this.handleError<any>('addFaq'))
        );
    }
    updateFaq (item: Faq): Observable<any> {
        return this.http.patch(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
          tap((item: Faq) => this.alertService.success(`The faq has been updated`)),
          catchError(this.handleError<any>('updateFaq'))
        );
    }
    updateFaqPosition (id:number, type:string='up'): Observable<any> {
        return this.http.post(this.apiUrl + '/updatePosition/' + id,{'action':(type=='up'?1:0)}, httpOptions).pipe(
          tap((item: Faq)=> {}),
          catchError(this.handleError<any>('updateFaq'))
        );
    }
    deleteFaq(id: number): Observable<any> {
        return this.http.delete<any>(this.apiUrl + '/' + id).pipe(
          map(response => {
            this.alertService.success(`The faq has been deleted`,true);
            return null;
          },
          catchError(this.handleError<any>('deleteFaq Error')))
        );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          //this.alertService.error(`UserService: ${operation} failed: ${error}`);
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}
