import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {User} from '../_models';
import {httpOptions} from '../_helpers/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private apiUrl = environment.apiUrl + '/products';  // URL to web api

  constructor(private http: HttpClient, private alertService: AlertService) { }

  getByUser(userId: number) {
    return this.http.get<any>(this.apiUrl + '/user/' + userId)
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('getByUser', [])))
      );
  }

  getByAccounts(accountId: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/account/' + accountId)
      .pipe(
        map(response => {
          if (response && response.status === 'success') {
            return response.data.product;
          }
          return [];
        },
        catchError(this.handleError('getByAccount', [])))
      )
  }

  getByMember(memberId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/member/' + memberId)
      .pipe(
        map(response => {
          if(response && response.status === 'success') {
            for( let i in response.data.product ) {
              response.data.product[i]['favoriteText'] = response.data.product[i]['favorite'] ? ' ★' : '';
            }
            return response.data.product;
          }
          return [];
        },
        catchError(this.handleError('getByMember', [])))
      )
  }

  getCategoryByUser(userId: number):Observable<any> {
    let a = this.http.get<any>(this.apiUrl + '/getCategory/' + userId);
    return this.http.get<any>(this.apiUrl + '/getCategory/' + userId)
      .pipe(
        map(response => {
          if (response && response.status === 'success') {
            return response.data;
          }
        })
      );
  }

  updateStatus (id: String, status: number): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/update-status/' + id, {'status' : status})
      .pipe(
        map(response => {
            this.alertService.success('Product\'s status has been updated!');
          },
          catchError(this.handleError('Whoops, looks like something went wrong!', [])))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.alertService.error(`${error}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
