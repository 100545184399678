import { Component, OnInit} from '@angular/core';
import { CurrencyService, PermissionService, AlertService, AuthenticationService } from '../_services';
import { Currency } from '../_models/currency';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Options, Globals } from '../shared';

@Component({
  selector: 'app-edit-currency',
  templateUrl: './edit-currency.component.html',
  styleUrls: ['./edit-currency.component.css']
})
export class EditCurrencyComponent implements OnInit {
  submitted: boolean;
  formBuilder: FormBuilder;
  currencyForm: FormGroup;
  currency  = new  Currency();
  backupCurrency: any;
  statusList:Options[] =[{'title':'Yes','value':true},
      {'title':'No','value':false}];
  constructor(private currService: CurrencyService, private permService: PermissionService, private route: ActivatedRoute, private router: Router, private alertService: AlertService, private globals: Globals) { 
  	this.formBuilder = new FormBuilder();
  }
  get f() { return this.currencyForm.controls; }

  ngOnInit() {
  	this.getCurrency();
  	this.currencyForm = this.formBuilder.group({
        shortcode: ['', Validators.required],
        name: ['', Validators.required],
        is_common: ['', Validators.required],
    });
  }
  getCurrency(): void {
  	const id = +this.route.snapshot.paramMap.get('id');
  	if(id){
		this.currService.getCurrency(id)
	      .subscribe(data => { 
	        this.currency = data.currency;  
          this.currencyForm.reset(this.currency);
          this.backupCurrency = Object.assign({}, this.currency);
	      });
  	}
  }
  onSubmit(currency) {
    this.submitted = true;
    if (this.currencyForm.invalid) {
      window.scroll(0,0);
      return;
    }
    let data  = Object.assign({}, currency);
    let values = this.currencyForm.value;
    data.name = values.name;
    data.shortcode = values.shortcode;
    data.is_common = (values.is_common == 'true'?true:false);
    this.globals.processing = true;
    this.currService.updateCurrency(data).subscribe(data => {
       if(data && data.status == 'success'){
          this.currency = data.data.currency;
          this.backupCurrency = Object.assign({}, this.currency);
       }
    });
    return;
  }
  backToList(){
      this.router.navigate(['currencies']);
  }
  resetForm(){
     this.submitted = false;
     this.currencyForm.reset(this.backupCurrency);
     window.scroll(0,0);
  }
}
