﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AmChartsModule } from '@amcharts/amcharts3-angular';

import {
  MatTabsModule, MatInputModule, MatTableModule, MatSortModule, MatToolbarModule, MatPaginatorModule, MatPaginator,
  MatDialogModule, MatNativeDateModule, MatDatepickerModule, MAT_DATE_FORMATS, MatProgressSpinnerModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { Globals } from './shared';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, UserService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { ExponentialStrengthPipe, ExchangeComponent, EditExchangeComponent } from './exchange';
import { DialogComponent } from './dialog/dialog.component';
import { UserComponent, UpdateUserComponent} from './user';
import { RoleComponent, AddRoleComponent, EditRoleComponent } from './role';
import { CurrencyComponent, EditCurrencyComponent } from './currency';
import { FaqComponent, AddFaqComponent } from './faq';
import { ScriptsComponent } from './scripts-statistic';
import { PlanComponent, AddPlanComponent } from './plan';
import { AdminComponent, UpdateAdminComponent } from './admin';
import { MemberComponent, AddMemberComponent } from './member';
import { PortfolioComponent, AddPortfolioComponent } from './portfolio';
import { BankComponent } from './bank/bank.component';
import { RelationshipComponent } from './relationship/relationship.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { AccountComponent } from './account/account.component';
import { AddAccountComponent } from './account/add-account.component';
import { DataProvidersComponent } from './data-providers/data-providers.component';
import { DataInspectionComponent } from './data-inspection/data-inspection.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CrosscheckComponent } from './cross-check';
import {DataInspectionRosoComponent} from './data-inspection-roso';
import {DataInspectionScriptComponent} from './data-inspection-script';
import {CloneUserComponent} from './user/clone-user.component';
import {DataProvidersProductListComponent} from './data-providers/data-providers-productList.component';
@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        TopNavComponent,
        SidebarComponent ,
        FooterComponent ,
        ExchangeComponent ,
        DialogComponent ,
        EditExchangeComponent,
        UserComponent,
        UpdateUserComponent,
        CloneUserComponent,
        RoleComponent,
        AddRoleComponent ,
        EditRoleComponent ,
        ExponentialStrengthPipe,
        CurrencyComponent,
        EditCurrencyComponent ,
        FaqComponent ,
        AddFaqComponent ,
        PlanComponent ,
        AddPlanComponent,
        AdminComponent,
        ScriptsComponent,
        UpdateAdminComponent,
        MemberComponent,
        AddMemberComponent,
        PortfolioComponent,
        AddPortfolioComponent,
        BankComponent,
        RelationshipComponent,
        AccountComponent,
        AddAccountComponent,
        DataProvidersComponent,
        CrosscheckComponent,
        DataInspectionComponent,
        DataInspectionRosoComponent,
        DataInspectionScriptComponent,
        DataProvidersProductListComponent,
    ],
    entryComponents: [
        DialogComponent,
        AddRoleComponent,
        EditRoleComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AngularEditorModule,
        routing,
        FormsModule,
        MatToolbarModule,
        MatTabsModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        MatDialogModule,
        AmChartsModule,
        AngularResizedEventModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        NgSelectModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        MatToolbarModule,
        MatTabsModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatPaginator,
        ExponentialStrengthPipe
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_DATE_FORMATS,
          useValue: {
            parse: {
              dateInput: ['DD-MM-YYYY'],
            },
            display: {
              dateInput: 'DD-MM-YYYY',
              dateA11yLabel: 'LL',
              monthYearLabel: 'MMM YYYY',
              monthYearA11yLabel: 'MMMM YYYY',
            },
          },
        },
        Globals,
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
