import { Component, OnInit } from '@angular/core';
import { GeneralUserComponent } from '../general-user.component';
import {CurrencyService, UserService} from '../_services';
import { MemberService } from '../_services/member.service';
import { PortfolioService } from '../_services/portfolio.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ProductService} from '../_services/product.service';
import {Currency} from '../_models';
import {Product} from '../_models/product';
import {BankService} from '../_services/bank.service';
import {RelationshipService} from '../_services/relationship.service';
import {Bank} from '../_models/bank';
import {Relationship} from '../_models/relationship';
import {Portfolio} from '../_models/portfolio';
import {Globals} from '../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-add-portfolio',
  templateUrl: './add-portfolio.component.html',
  styleUrls: ['./add-portfolio.component.css']
})
export class AddPortfolioComponent extends GeneralUserComponent implements  OnInit {

  formBuilder: FormBuilder;
  portfolioForm: FormGroup;
  userId = 101;
  memberId = 105;
  currencies: Currency[];
  products: Product[];
  banks: Bank[];
  relationships: Relationship[];
  submitted: boolean;
  portfolio = new Portfolio();
  backupPortfolio = new Portfolio();

  constructor(public userService: UserService, public memberService: MemberService, private portfolioService: PortfolioService,
              private currencyService: CurrencyService, private bankService: BankService, private productService: ProductService,
              private relationshipService: RelationshipService, private globals: Globals, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog) {
    super(userService, memberService);
    this.formBuilder = new FormBuilder();
  }

  get f() { return this.portfolioForm.controls; }

  ngOnInit() {
    if (localStorage.getItem('portfolioUserId')) {
      this.userId = +localStorage.getItem('portfolioUserId');
    }
    if (localStorage.getItem('portfolioMemberId')) {
      this.memberId = +localStorage.getItem('portfolioMemberId');
    }
    this.portfolioForm = this.formBuilder.group({
      id_user: [this.userId, Validators.required],
      id_member: [this.memberId, Validators.required],
      name: ['', Validators.required],
      id_bank: [null, Validators.required],
      id_currency: [null, Validators.required],
      id_relationship: [],
      id_benchmark: [],
      id_benchmark_addition: [],
      has_cash: [],
      transaction_fee_date: [0],
      is_active: [1]
    });
    this.getPortfolio();
    this.getAllUsers();
    this.getMembersByUser(this.userId);
    this.getAllCurrencies();
    this.getProductsByUser();
    this.getBanksByUser();
    this.getRelationshipsByUser();
  }

  getAllCurrencies(): void {
    this.currencyService.getAllCurrencies().subscribe(currencies => this.currencies = currencies.currencies);
  }

  getProductsByUser(userId: number = this.userId): void {
    this.productService.getByUser(userId).subscribe(products => this.products = products.products);
  }

  getBanksByUser(userId: number = this.userId): void {
    this.bankService.getByUser(userId).subscribe(banks => this.banks = banks.banks);
  }

  getRelationshipsByUser(userId: number = this.userId): void {
    this.relationshipService.getByUser(userId).subscribe(relationships => this.relationships = relationships.relationships);
  }

  getPortfolio(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.portfolioService.getPortfolio(id)
        .subscribe(data => {
          localStorage.setItem('portfolioUserId', data.portfolio.id_user);
          if (data.portfolio.id_benchmark === 0) {
            data.portfolio.id_benchmark = null;
          }
          if (data.portfolio.id_relationship === 0) {
            data.portfolio.id_profile = null;
          }
          this.portfolio = data.portfolio;
          this.portfolioForm.reset(this.portfolio);
          this.portfolioForm.get('id_user').disable();
          this.portfolioForm.get('id_currency').disable();
          this.portfolioForm.get('id_member').disable();
          if (data.portfolio.has_cash === false) {
            this.portfolioForm.get('transaction_fee_date').disable();
            this.portfolio.transaction_fee_date = 0;
          }
          this.backupPortfolio = Object.assign({}, this.portfolio);
        });
    } else {
      this.portfolioForm.get('transaction_fee_date').disable();
      this.backupPortfolio.id_user = this.userId;
      this.backupPortfolio.id_member = this.memberId;
      this.backupPortfolio.id_bank = null;
      this.backupPortfolio.id_relationship = null;
      this.backupPortfolio.id_benchmark = null;
      this.backupPortfolio.transaction_fee_date = 0;
      this.backupPortfolio.is_active = 1;
    }
  }

  onSubmit(portfolio: Portfolio): void {
    this.submitted = true;
    const values = this.portfolioForm.value;
    if (this.portfolioForm.invalid) {
      return;
    }
    this.globals.processing = true;
    if (portfolio.id) {
      const data  = Object.assign({}, portfolio);
      data.name = values.name;
      data.id_bank = values.id_bank;
      data.id_benchmark = values.id_benchmark;
      data.id_relationship = parseInt(values.id_relationship) || null;
      data.is_active = values.is_active;
      data.has_cash = values.has_cash;

      if (data.has_cash === false) {
        data.transaction_fee_date = 0;
      } else {
        data.transaction_fee_date = parseInt(values.transaction_fee_date);
      }
      this.portfolioService.updatePortfolio(data).subscribe(data => { this.getPortfolio(); });
    } else {
      this.portfolioService.addPortfolio(values).subscribe(data => {
        if (data && data.status === 'success') {
          this.backToList();
        }
      });
    }
  }

  onChangeUser(userId): void {
    localStorage.setItem('portfolioUserId', userId);
    this.getMembersByUser(userId);
    this.getBanksByUser(userId);
    this.getRelationshipsByUser(userId);
  }

  onChangeMember(memberId): void {
    localStorage.setItem('portfolioMemberId', memberId);
  }

  backToList() {
    this.router.navigate(['portfolios']);
  }

  openConfirmDialog(portfolioId): void {
    if (!portfolioId) { return; }
    const dialogRef = this.dialog.open(DialogComponent , {
      width: '350px',
      data: {type: true, message: 'Are you sure you want to delete this portfolio?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.portfolioService.deletePortfolio(portfolioId).subscribe(data => {
          if (!data) {
            this.router.navigate(['portfolios']);
          }
        });
      }
    });
  }

  toggleTransactionFeeDate(e): void {
    if (e.target.checked === true) {
      this.portfolioForm.get('transaction_fee_date').enable();
    } else {
      this.portfolioForm.patchValue({'transaction_fee_date': '0'});
      this.portfolioForm.get('transaction_fee_date').disable();
    }
  }

  focusBenchmarkAddition(benchmarkAddition: any, value): void {
  }

  focusoutBenchmarkAddition(benchmarkAddition: any, value): void {
    if (value > 100) { value = 100; }
    if (value < -100) { value = -100; }

    return;
  }

  resetForm() {
    this.submitted = false;
    this.portfolioForm.reset(this.backupPortfolio);
  }
}
