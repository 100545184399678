import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {httpOptions, CustomURLEncoder} from '../_helpers/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AlertService} from '../_services/alert.service';
import {Member} from '../_models/member';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private apiUrl = environment.apiUrl + '/members';  // URL to web api

  constructor(private http: HttpClient, private alertService: AlertService) {
  }

  getMembers(user_id: number, keyword: string = '', offset: number = 0, limit: number = 10, sortBy: string = 'name_first',
             sortType: string = 'asc'): Observable<any> {
    let params = new HttpParams({encoder: new CustomURLEncoder()}).set('limit', `${limit}`).set('page', `${offset}`);
    if (keyword) {
      params = params.append('filter[name_first]', `${keyword}`);
    }
    params = params.append('filter[id_user]', `${user_id}`);
    params = params.append('sortBy', `${sortBy}`);
    params = params.append('sortType', `${sortType}`);
    return this.http.get<any>(this.apiUrl, {params})
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('getMembers', [])))
      );
  }

  getInspectionMembers(user_id: number, offset: number = 0, limit: number = 10, sortBy: string = 'name_first',
             sortType: string = 'asc'): Observable<any> {
    let params = new HttpParams({encoder: new CustomURLEncoder()}).set('limit', `${limit}`).set('page', `${offset}`);
    params = params.append('filter[id_user]', `${user_id}`);
    params = params.append('filter[inspection]', `1`);
    params = params.append('sortBy', `${sortBy}`);
    params = params.append('sortType', `${sortType}`);
    return this.http.get<any>(this.apiUrl, {params})
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('getMembers', [])))
      );
  }

  getInspectableMembers(user_id: number): Observable<any> {
    let InspectableMemberUrl = this.apiUrl + '/inspection/';
    return this.http.get<any>(InspectableMemberUrl +user_id)
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data.member;
            }
            return [];
          },
          catchError(this.handleError('getMembers', [])))
      );
  }

  getMember(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + id).pipe(
      map(response => {
          if (response && response.status === 'success') {
            return response.data;
          }
          return [];
        },
        catchError(this.handleError<any>('getMember', [])))
    );
  }

  addMember(item: any): Observable<any> {
    return this.http.post<Member>(this.apiUrl, item, httpOptions).pipe(
      tap(_ => this.alertService.success(`The member has been added`, true)),
      catchError(this.handleError<any>('addMember'))
    );
  }

  updateMember(item: Member): Observable<any> {
    return this.http.patch(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
      tap(_ => this.alertService.success(`The member has been updated`)),
      catchError(this.handleError<any>('updateMember', []))
    );
  }

  deleteMember(id: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/' + id).pipe(
      map(_ => {
        this.alertService.success(`The member has been deleted`, true);
        return null;
      }),
      catchError(this.handleError<any>('deleteMember Error', [false]))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.alertService.error(`${error}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
