﻿import {Component, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { ScriptService } from '../_services';
import {ResizedEvent} from 'angular-resize-event';

declare var $: any;
// import * as $ from 'jquery';

@Component({
    selector: 'app-root',
    templateUrl: './scripts.component.html',
    styleUrls: ['./scripts.component.css'],
    // template: `<div id="chartdiv" [style.width.%]="100" [style.height.px]="600"></div>`
})

export class ScriptsComponent implements OnInit {
    displayedColumns: string[] =  ['name', 'purpose', 'month', 'date', 'day', 'hour', 'minute'];
    tabs = [];
    dataSource = [];
    chart: AmChart;
    elWidth: number;
    chartData;

    constructor(private scriptService: ScriptService, private AmCharts: AmChartsService) {}

    ngOnInit() {
      this.getScripts();
    }

    ngAfterViewInit() {}

    onResized(event: ResizedEvent) {
      this.elWidth = event.newWidth;
      if (typeof this.chartData !== 'undefined') {
        drawChart.call(this, this.chartData);
      }
    }

    getScripts(keyword: string = ''): void {
      this.scriptService.getScripts(keyword)
        .subscribe(data => {
          // this.dataSource.data = data.scripts;
          this.explodeData(data);
          var chartData = prepairData(data.statistic);
          this.chartData = chartData;
          drawChart.call(this, chartData);
        });
    }

    explodeData(data) {
      this.tabs = Object.keys(data.scripts);
      let _source = this.dataSource;
      this.tabs.forEach(function(v, i) {
        _source[i] = new MatTableDataSource(data.scripts[v]);
      })
    }

    ngOnDestroy() {
      if (this.chart) {
        this.AmCharts.destroyChart(this.chart);
      }
    }
}

function drawChart(data) {
  var chartData,
      graphs = [],
      graphItems,
      legend,
      chartObj,
      _chart;
  chartData = data.chartData;
  graphItems = data.graphItems;
  var valueAxis = {
      axisColor: '#FFFFFF',
      axisThickness: 1,
      gridAlpha: 0,
      position: 'right',
      color: '#666666',
      precision: 2,
      usePrefixes: true,
      tickLength: 0,
      labelFunction: formatLabel,
      minimum: -1
  },
  categoryAxis = {
      parseDates: 1,
      dashLength: 2,
      gridAlpha: 0.15,
      axisColor: '#666666',
      axisAlpha: 0.6,
      color: '#666666',
      usePrefixes: true
  };
  var items = {},  j = 0, k = 0, step;
  if (this.elWidth > 1570) {
    step = 4;
  } else if (this.elWidth <= 1570 && this.elWidth > 1208) {
    step = 3;
  } else if (this.elWidth <= 1208 && this.elWidth >= 835) {
    step = 2;
  } else {
    step = 1;
    j = 4;
  }
  // order legend following client request
  graphItems.forEach(function(v, i) {
    if (v.indexOf('precomputePositionSpecial') === 0) {
      items[0] = v;
    } else if (v.indexOf('precomputePosition0') === 0) {
      k += step;
      items[k] = v;
    } else {
      j += 1;
      if (j % step === 0 && step != 1) {
        j += 1;
      }
      items[j] = v;
    }
  });
  // GRAPHS
  Object.keys(items).forEach(function(i) {
      var graph = {
          valueField: items[i],
          title: items[i],
          lineThickness: 1.5,
          balloonText: '<b>' + items[i] + '</b>' + '[[' + items[i] + 'Balloon]]',
      };
      graphs.push(graph);
  });

  legend = {
      align: 'center',
      color: '#575555',
      valueWidth: 50,
      markerType: 'circle',
      maxColumns: 4
  };
  chartObj = {
      data: chartData,
      graphs: graphs,
      categoryField: 'date',
      categoryAxis: categoryAxis,
      parseDates: false,
      valueAxes: [valueAxis],
      legend: legend
  };
  _chart = new Serial(chartObj);
  _chart.colors = ["#205087", "#e91e63", "#009688", "#ffdb3b", "#ff9800", "#27d8f7", "#883316", "#836bb7", "#7d9a24", "#0eb0f4"];
  // _chart.autoMargins = true;
  // _chart.autoMarginOffset = 0;

  _chart.balloon.color = '#575555';

  // CURSOR
  // _chart.chartCursor.cursorPosition = 'mouse';
  _chart.chartCursor.categoryBalloonDateFormat = 'DD-MMM-YYYY';
  _chart.chartCursor.cursorColor = '#ffa500';
  _chart.categoryAxis.parseDates = true;
  _chart.export = {
      enabled: true
  }
  typeof this.chart == 'object' && this.chart.clear();
  this.chart = this.AmCharts.makeChart('statistic-chart', _chart);
}

function prepairData(data) {
    var chartData = [],
        itemNames = [],
        scriptName = $('#scriptName').val();
    // var $originalData = data.statistic;
    var $originalData = Object.keys(data).sort().reduce(function(o, v) {
        o[v] = data[v];
        return o;
    }, {});
    var today = new Date();
    today.setMonth(today.getMonth() - 1);
    // var todayFormatted = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    Object.keys($originalData).forEach(function(date) {
        // if (date < todayFormatted) {
        //   return false;
        // }
        var object = $originalData[date],
            newItem = {
                date: date
            };

        $.each(object, function(name, val) {
            /*if (scriptName != 0 && name != scriptName) {
                return true;
            }*/
            name = name.replace(/\s/g, '');
            var _formattedAverage = formatValue(val.average, 0),
                _formattedMin = formatValue(val.min, 0),
                _formattedMax = formatValue(val.max, 0),
                _average = '<span ' + (val.average > 900 ? ('class=\"text-red\">' + _formattedAverage) : ('>' + _formattedAverage)) + 's<\/span>',
                _max = '<span ' + (val.max > 900 ? ('class=\"text-red\">' + _formattedMax) : ('>' + _formattedMax)) + 's<\/span>',
                _min = '<span ' + (val.min > 900 ? ('class=\"text-red\">' + _formattedMin) : ('>' + _formattedMin)) + 's<\/span>';
            newItem[name] = val.average;
            newItem[name + 'Balloon'] = ' [' + val.count + ']: <b>Average</b>: ' + _average + ' | <b>Min</b>: ' + _min + ' | <b>Max</b>: ' + _max;
            !~itemNames.indexOf(name) && itemNames.push(name);
        });
        chartData.push(newItem);
    });

    return {
        chartData: chartData,
        graphItems: itemNames.sort()
    };
}

function formatLabel(value, valueString, axis) {
  valueString = '';
  var formatted = customFormatCategoryAxis(value, axis);
  valueString = formatted != '' ? formatted : valueString;

  if (valueString != '') {
      return valueString;
  }
  return value.toFixed(2);
}

function formatValue(val, decimal, prependSign = '', extraSign = '') {
    var _decimal = decimal || 2,
        _prependSign = prependSign || false,
        _extraSign = extraSign || '',
        parts;
    if (isNaN(val)) {
        if (!/,/.test(val)) {
            return '';
        }
        val = val.replace(/,/g, '');
    }
    if (_decimal >= 0) {
        var roundedValue = Math.round(parseFloat(val));
        var roundedNumber = parseFloat(val).toFixed(_decimal);
        parts = roundedNumber.toString().split('.');
    } else {
        parts = parseFloat(val).toString().split('.');
    }

    parts[0] = (_prependSign && roundedValue > 0 ? '+' : '') + parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.') + _extraSign;
}


/**
 * common serial chart object
 */
function Serial(obj) {
    if (obj.data == undefined || obj.graphs == undefined) {
        console.log('Missing object properties. data & graph are required');
        return;
    }
    this.isValid = true;
    this.type = 'serial';
    this.theme = 'light';
    this.pathToImages = 'assets/amcharts/images/';
    this.addClassNames = true;
    var _valueAxes = [];
    obj.valueAxes = (undefined !== obj.valueAxes) ? obj.valueAxes : [{}];
    obj.valueAxes.forEach(function(v) {
        _valueAxes.push($.extend({}, {
            position: 'right',
            color: '#575555',
            axisColor: '#575555',
            axisAlpha: 0,
            gridAlpha: 0,
            tickLength: 0,
            zeroGridAlpha: 0.2,
            usePrefixes: true,
            // precision: 2,
            labelFunction: function(value, valueString, axis) {
                var formatted = customFormatCategoryAxis(value, axis);
                valueString = formatted != '' ? formatted : valueString;
                return valueString;
            }
        }, v));
    });
    this.legend = obj.legend;
    this.valueAxes = _valueAxes;
    this.balloon = {
        textAlign: 'left',
        color: '#575555',
        animationDuration: 0.2
    };
    // this.dataDateFormat = 'MMM YYYY';
    this.addClassNames = true;
    this.categoryField = obj.categoryField || 'date';
    this.categoryAxis = {
        gridAlpha: 0,
        axisAlpha: 0,
        axisColor: '#575555',
        color: '#575555',
        parseDates: obj.parseDates !== undefined ? obj.parseDates : true,
        equalSpacing: true,
        tickLength: 0,
        // dateFormats: [{"period":"fff","format":"JJ:NN:SS"},{"period":"ss","format":"JJ:NN:SS"},{"period":"mm","format":"JJ:NN"},{"period":"hh","format":"JJ:NN"},{"period":"DD","format":"MMM DD"},{"period":"WW","format":"MMM DD"},{"period":"MM","format":"MMM YYYY"},{"period":"YYYY","format":"YYYY"}]
    };

    this.dataProvider = obj.data;
    this.graphs = obj.graphs;
    this.chartCursor = {
        animationDuration: 0,
        cursorPosition: 'middle',
        valueLineEnabled: false,
        valueLineBalloonEnabled: false,
        cursorColor: '#FFA500',
        categoryBalloonDateFormat: 'DD-MMM-YYYY'
    };
    this.colors = ['#4976a3'];
    if (obj.listeners !== undefined) {
        this.listeners = obj.listeners;
    }

    this.balloon.maxWidth = 'auto';
}


/**
 * format category axis label on chart
 */
function customFormatCategoryAxis(value, axis) {
    var valueString = '',
        minAbs = Math.abs(Math.round(axis.min * 100) / 100),
        maxAbs = Math.abs(Math.round(axis.max * 100) / 100),
        maxValue = minAbs > maxAbs ? minAbs : maxAbs;

    if (maxValue > 999999999.99) {
        valueString = (value / 1000000000).toFixed(2) + 'G';
    } else if (maxValue > 999999.99) {
        valueString = (value / 1000000).toFixed(2) + 'M';
    } else if (maxValue > 999.99) {
        valueString = (value / 1000).toFixed(2) + 'k';
    }
    return valueString;
}
