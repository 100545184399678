import { HttpHeaders, HttpParameterCodec } from '@angular/common/http';

export class CustomURLEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key); 
    }
    encodeValue(key: string): string { 
        return encodeURIComponent(key); 
    }
    decodeKey(key: string): string { 
        return decodeURIComponent(key); 
     }
    decodeValue(key: string) {
        return decodeURIComponent(key); 
     }
}

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Credentials':'true',
    'Access-Control-Allow-Headers':'Cache-Control, Pragma, Origin, Authorization, Content-Type, X-Requested-With'})
};