export class Member {
    id: number;
    id_user: number;
    name_first: string;
    email: string;
    id_profile: number;
    id_currency: number;
    id_benchmark: number;
    favorite: boolean;
    status: boolean;
    permissions: Array<string>;
}
