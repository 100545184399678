import {Component, OnInit, OnDestroy} from '@angular/core';
import {MarketMetricsService} from '../_services/market-metrics.service';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../shared';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../_services/product.service';

@Component({
  selector: 'data-providers',
  templateUrl: './data-providers-productList.component.html',
  styleUrls: ['./data-providers.component.css']
})
export class DataProvidersProductListComponent implements OnInit, OnDestroy {

  public products;
  public noProduct: boolean;
  private productSubscription;
  public searchName: string;
  public loading = false;
  public showSuccess = false;
  isError = false;
  statuses = [
    {value: 0, text: 'Active'},
    {value: 1, text: 'Inactive'},
  ];

  constructor(public marketMetrics: MarketMetricsService, public productService: ProductService,  private http: HttpClient, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.searchName = '';
    this.getProducts();
  }

  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }

  search(searchName: string): void {
    this.searchName = searchName.trim();
    this.getProducts();
  }

  getProducts() {
    const type = +this.route.snapshot.paramMap.get('type');
    const source = +this.route.snapshot.paramMap.get('source');
    const idii = this.route.snapshot.paramMap.get('idii');
    this.productSubscription = this.marketMetrics.getListProducts(type, source, idii, this.searchName).subscribe(products => {
      this.products = Object.values(products.products);
      if (this.products.length && this.products[0] !== 'New products record is empty') {
        this.noProduct = false;
      } else {
        this.noProduct = true;
      }
    });
  }

  showStatus(sts) {
    const selected = this.statuses.filter(el => el.value === parseInt(sts.toString()));
    return selected.length > 0 ? selected[0].text.toString() : 'Not Set';
  }

  saveStatus(val, item) {
    this.loading = true;
    this.products[item].status = val;
    this.products[item].toggleList = false;
    this.productService.updateStatus(this.products[item].id.toString(), val).subscribe(data => {
      this.loading = false;
      // this.showSuccess = true;
      // setTimeout(() => this.showSuccess = false, 3000);
    }, error => {
      this.loading = false;
      this.isError = true;
    });
  }

}

