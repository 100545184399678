import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class MarketMetricsService {
  private apiUrl = environment.apiUrl + '/market-metrics';
  private apiProductListUrl = environment.apiUrl + '/products/source';

  constructor(private http: HttpClient, private alertService: AlertService) { }

  getNewProducts() {
    return this.http.get<any>(this.apiUrl)
    .pipe(
      map(response => {
          return response.data;
        },catchError(catchError(this.handleError('getMembers', []))))
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(`${error}`);
      this.alertService.error(`${error}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getListProducts(type, source, idii, name) {
    let params = new HttpParams({encoder: new CustomURLEncoder()});
    params = params.append('source_type', `${source}`);
    params = params.append('type', `${type}`);
    params = params.append('idii', `${idii}`);
    if(name != ''){
        params = params.append('name', `${name}`);      
    }
    return this.http.get<any>(this.apiProductListUrl, {params})
        .pipe(
            map(response => {
                return response.data;
            },catchError(catchError(this.handleError('getMembers', []))))
        );
    }
}
