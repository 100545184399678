import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Exchange } from '../_models/exchange';
import { AlertService } from '../_services/alert.service';

@Injectable({
	providedIn: 'root'
})
export class ExchangeService {
	private apiUrl = environment.apiUrl + '/xigniteExchanges'; // URL to web api
	constructor(private http: HttpClient, private alertService: AlertService) {}

	getExchanges(
		offset: number = 0,
		limit: number = 10,
		keyword: string = '',
		sortBy: string = 'name',
		sortType: string = 'asc'
	): Observable<any> {
		let params = new HttpParams({ encoder: new CustomURLEncoder() })
			.set('limit', `${limit}`)
			.set('page', `${offset}`)
			.set('sortBy', 'id');

		//let params = {limit:limit,page:offset,sortBy:'id',keyword: ''} ;
		if (keyword != '') {
			params = params.append('keyword', `${keyword}`);
		}
		params = params.append('sortBy', `${sortBy}`);
		params = params.append('sortType', `${sortType}`);
		return this.http.get<any>(this.apiUrl, { params }).pipe(
			map((response) => {
				if (response && response.status == 'success') {
					return response.data;
				}
				return [];
			}, catchError(this.handleError('getExchange', [])))
		);
	}
	/** GET hero by id. Will 404 if id not found */
	getExchange(id: number): Observable<any> {
		return this.http.get<any>(this.apiUrl + '/' + id).pipe(
			map((response) => {
				if (response && response.status == 'success') {
					return response.data;
				}
				return [];
			}, catchError(this.handleError<any>('getExchange Error')))
		);
	}
	/** POST: add a new Exchange to database */
	addTaxRule(item: any): Observable<any> {
		return this.http
			.post<Exchange>(this.apiUrl + '/taxRules', item, httpOptions)
			.pipe(
				tap((item: any) => this.alertService.success(`The tax rule has been added`)),
				catchError(this.handleError<any>('addTaxRules'))
			);
	}
	/** PUT: update the Exchange on database */
	updateTaxRule(item: any): Observable<any> {
		return this.http
			.patch(this.apiUrl + '/taxRules/' + item.id, item, httpOptions)
			.pipe(
				tap((item: any) => this.alertService.success(`The tax rule has been updated`)),
				catchError(this.handleError<any>('updateTaxRule'))
			);
	}
	/** PUT: update the Exchange on database */
	updateExchange(item: Exchange): Observable<any> {
		return this.http
			.patch(this.apiUrl + '/' + item.id, item, httpOptions)
			.pipe(
				tap((item: Exchange) => this.alertService.success(`This exchange has been updated`)),
				catchError(this.handleError<any>('updateExchange'))
			);
	}

	deleteTaxRule(id: number): Observable<any> {
		return this.http.delete<any>(this.apiUrl + '/taxRules/' + id).pipe(
			map((response) => {
				return null;
			}, catchError(this.handleError<any>('deleteTaxRule Error')))
		);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			//console.error(error); // log to console instead
			// TODO: better job of transforming error for user consumption
			this.alertService.error(`${error}`);
			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
