import { Injectable } from "@angular/core";
import { AuthenticationService } from '../_services';

@Injectable()
export class Globals {
  processing: boolean = false;
  curPermission: string[];
  constructor(private authService: AuthenticationService) {
  	if(this.authService.isLoggedIn){
		this.authService.currentUser.subscribe(x => this.curPermission = x.permissions );
  	}
  }
  hasPermission(perm:string): boolean{
    return (this.curPermission.indexOf(perm) !== -1);
  }
}