import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog, MatPaginatorModule, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { tap, filter } from 'rxjs/operators';
import { FaqService } from '../_services';
import { Globals } from '../shared';

declare function scrollUpDown():any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements AfterViewInit, OnInit {
  faqs: any;
  displayedColumns: string[] =  ['position','subject','action'];

  totalItems = 100;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private faqService: FaqService, private globals: Globals) {
  	this.faqs = [];
  }
  ngOnInit() {
  	this.getTopics();
  }
  ngAfterViewInit() {
  	setTimeout(() => {
      scrollUpDown();
    }, 2000);
  }
  updateFaqList(index,list,currPage = 1){
    let dataSource = new MatTableDataSource();
    dataSource.data = list.faqs;
    this.faqs[index].dataSource = dataSource;
    this.faqs[index].length = dataSource.data.length;
    this.faqs[index].total = list.pagination.total;
    this.faqs[index].last_page = list.pagination.last_page;
    this.faqs[index].currPage = currPage;
  }
  getTopics(offset: number = 1, limit: number = 10, keyword: string=''): void {
    this.faqService.getTopics()
      .subscribe(topics => {
      	topics.forEach((group, index)   => {
      	  this.faqs[index] = {'group':group,'dataSource': null,'length':0};
          this.faqService.getFaqs(group.id).subscribe(list=>{
            this.updateFaqList(index,list);
          });
        });
      });
  }
  swapFaq(type, faqIndex, gIndex):void {
    if(!this.globals.processing && this.globals.hasPermission('edit faqs')){
      this.globals.processing = true;
      this.faqService.updateFaqPosition(this.faqs[gIndex].dataSource.data[faqIndex].id, type).subscribe(data=>{
        this.faqService.getFaqs(this.faqs[gIndex].group.id,this.faqs[gIndex].currPage).subscribe(list=>{
          this.updateFaqList(gIndex,list,this.faqs[gIndex].currPage);
          this.globals.processing = false;
        });
      });
    }
  }
  onPaginateChange(pageEvent, gid, gIndex):void {
    let currentPage = pageEvent.pageIndex + 1;
    this.faqService.getFaqs(gid,currentPage).subscribe(list=>{
      this.updateFaqList(gIndex,list,currentPage);
    });
  }
}
