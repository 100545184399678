export class Plan {
	id: number;
	name: string = '';
	max_member: number = null;
	max_portfolio: number = null;
	max_account: number = null;
	max_product: number = null;
	price: number = null;
	description: string = '';
	status: number = 1;
	is_public: number = 0;
	method: string = '';
	trial_cycles: number = null;
}
