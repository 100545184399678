﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { Globals } from '../shared/globals';

import { AlertService } from '../_services';

@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        bottom: '5%',
        opacity: 1,
      })),
      state('closed', style({
        bottom: '0%',
        opacity: 0,
        display: 'none'
      })),
    transition('* => closed', [
        animate('0.2s')
      ]),
      transition('* => open', [
        animate('0.2s')
      ])
    ]),
  ],
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    isOpen = true;
    constructor(private alertService: AlertService, private globals: Globals) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.isOpen = true;
            this.message = message;
            setTimeout(() => {
              this.close();
            }, 3000);
        });
    }

    ngOnDestroy() {
        if(this.subscription){
          this.subscription.unsubscribe();
        }
    }
    close() {
      this.isOpen = false;
      this.globals.processing = false;
    }
}
