import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../_services';
import { MemberService } from '../_services/member.service';
import { AccountService } from '../_services/account.service';
import { ProductService } from '../_services/product.service';
import { DataInspectionService } from '../_services/data-inspection.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
const moment =  _moment;

declare function makeDatePicker(): any;

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'DD-MMM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export const validation: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	let from = control.get('date_from');
	let to = control.get('date_to');
	let id_account = control.get('id_account');
	return (from && to && id_account && from.value != ""  && to.value != "" && from.value >= to.value) ? { 'validation': true} : null;
};

@Component({
	selector: 'data-inpsection',
	templateUrl: './data-inspection.component.html',
	styleUrls: ['./data-inspection.component.css'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		  },
	  
		  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	]
})

export class DataInspectionComponent implements OnInit {
	public GIAForm: FormGroup;
	public formBuilder: FormBuilder;
	public debugType = [
		{
			'type': 'GAINS_IN_ACCOUNT',
			'name': 'Gains In Account',
		}, {
			'type': 'PERFORMANCES_AND_CONTRIBUTIONS',
			'name': 'Old PAC',
		}, {
			'type': 'PERFORMANCES_AND_CONTRIBUTIONS_CCY',
			'name': 'Old PAC CCY',
		}, {
			'type': 'NEW_PERFORMANCES_AND_CONTRIBUTIONS',
			'name': 'New PAC',
		}, {
			'type': 'NEW_PERFORMANCES_AND_CONTRIBUTIONS_CCY',
			'name': 'New PAC CCY',
		}, {
			'type': 'PRODUCTS_IN_ACCOUNT',
			'name': 'Products In Account',
		}, {
			'type': 'PRODUCTS_IN_ACCOUNT_LIGHT',
			'name': 'Products In Account Light',
		},
	];
	public debugTypeSelected = 'GAINS_IN_ACCOUNT';
	public userId = 101;
	public memberId;
	public accounts = [];
	public selectedAccounts = [];
	public products = [];
	public categoryLabel = [];
	public categoryData = [];
	public categoryField = [];
	public excelFiles;
	users = [];
	members = [];
	accByMemArr = [];
	loading = false;
	emptyAcc = true;
	isSubmit = false;
	maxDate;
	minDate;
	maxDateFrom;

	constructor( public userService: UserService, public memberService: MemberService,
		private http: HttpClient, public accountService: AccountService, public DataInspectionService: DataInspectionService,
		private ProductService: ProductService ) {
		this.formBuilder = new FormBuilder();
		this.maxDateFrom = this.maxDate = moment();
	}
	ngOnInit() {
		this.GIAForm = new FormGroup({
			typeCalculate: new FormControl(this.debugType[0]['type']),
			date_from: new FormControl(this.maxDateFrom),
			date_to: new FormControl(this.maxDate),
			id_account: new FormControl(),
			include_fees: new FormControl(1),
			use_computed_quantity_left: new FormControl(1),
			id_product: new FormControl(),	// used by PIA, GIA
		})
		this.loadComponent();
		this.loadScript();
		this.GIAForm.get('date_from').valueChanges.subscribe(val => {
			this.minDate = val;
		})
		this.GIAForm.get('date_to').valueChanges.subscribe(val => {
			this.maxDateFrom = val;
		})

		this.GIAForm.get('id_product').valueChanges.subscribe(val => {
			if (typeof(val) !== 'undefined' && val > 0) {
				this.getAccByProduct(val);
			} else {
				this.accounts = this.accByMemArr;
			}
			this.selectedAccounts = [];
			this.excelFiles = false;
			this.isSubmit = false;
		})
		this.GIAForm.get('typeCalculate').valueChanges.subscribe( val => {
			this.debugTypeSelected = val;
			this.isSubmit = false;
		})
		this.GIAForm.get('id_account').valueChanges.subscribe(val => {
			if (val.length > 0) {
				this.emptyAcc = false;
			} else {
				this.emptyAcc = true;
			}
			this.isSubmit = false;
		})
		this.GIAForm.get('typeCalculate').valueChanges.subscribe( val => {
			if (val.length > 0) {
				this.onChangeUser(this.userId);
				this.isSubmit = false;
			}
		})
	}

	ngOnDestroy() {
	}

	loadScript() {
		let script = document.createElement('script');
		script.innerHTML = '';
		script.src = 'assets/js/jquery-ui.js';
		script.async = true;
		script.defer = true;
		document.getElementsByTagName('head')[0].appendChild(script);

		let element = document.createElement('link');
		element.rel = 'stylesheet';
		element.href = 'assets/css/jquery-ui.css';
		document.getElementsByTagName('head')[0].appendChild(element);
		setTimeout(() => {
			makeDatePicker();
		}, 1000);
	}

	loadComponent() {
		this.getAllUsers();
	}

	// called when the first time page load
	getInspectableMembers(): void {
		this.memberService.getInspectableMembers(this.userId)
		.subscribe(members => {
		  this.members = members;
		  this.memberId = this.members[0].id;
		  this.getAccountsByMemberId(this.memberId);
		  this.getProductsByMember(this.memberId);
		});
	}

	onChangeUser(userId: number): void {
		this.userId = userId;
		localStorage.setItem('userId', userId.toString());
		this.getInspectableMembers();
		setTimeout(() => {
			if (this.members.length === 0) {
				this.memberId = 0;
			}
			this.onChangeMember(this.memberId);
		}, 200);
		this.ProductService.getCategoryByUser(userId).subscribe( data => {
			this.categoryLabel = data.categoryLabel;
			this.categoryData = data;
			this.categoryField = [];
			let category = document.getElementById('category');
			if (category) {
				category.classList.add('reset');
			}
		});
	}
	asIsOrder(a, b) {
		return 1;
	}

	onChangeCategory(category: string): void {
		let categoryElement = (<HTMLInputElement>document.getElementById('category'));
		if (category != '') {
			categoryElement.classList.remove('reset');
			this.categoryField = this.categoryData[category];
		} else {
			categoryElement.classList.add('reset');
			this.categoryField = [];
		}
	}

	onChangeMember(memberId: number): void {
		this.memberId = memberId;
		this.GIAForm.patchValue({
			'id_account' : [],
		})
		this.getAccountsByMemberId(memberId);
		this.getProductsByMember(memberId);
		this.GIAForm.patchValue({'id_product': []});
	}

	getAccByProduct(idProduct: number) {
		this.accountService.getByProductId(idProduct, this.memberId).subscribe(accounts => {
			this.accounts = accounts.account;
		})
	}

	getAccountsByMemberId(memberId: number): void {
		this.accountService.getAccountsByMemberId(memberId).subscribe(accounts => {
			this.accounts = accounts.accounts;
			this.accByMemArr = accounts.accounts;
		});
		this.excelFiles = false;
	}

	getAllUsers(): void {
		this.userService.getAllInspectableUser().subscribe(users => {
			this.users = users;
			let hasUser101 = false;
			for (let i = 0; i < users.length; i++) {
				if (users[i]['id'] == 101) {
					hasUser101 = true;
					break;
				}
			}
			if (!hasUser101) {
				this.userId = users[0]['id'];
			}
			this.getInspectableMembers();
		});
	}

	getProductsByMember(memberId: number): void {
		this.ProductService.getByMember(memberId).subscribe( products => {
			this.products = products;
		});
		this.selectedAccounts = [];
	}

	groupValueProduct(_: string, products: any[]) {
		return {
			productTypeName: products[0].productTypeName,
			icon: products[0].icon,
		}
	}

	onSubmit(): void {
		this.isSubmit = true;
		this.excelFiles = false;
		if (!this.emptyAcc) {
			let submitForm = this.buildRequestForm();
			this.loading = true;
			this.DataInspectionService.getResult(submitForm).subscribe( data => {
				this.excelFiles = data;
				this.loading = false;
				this.isSubmit = false;
			})
		}
	}

	buildRequestForm(): object {
		let token = JSON.parse(localStorage.currentUser)['token'];
		let form = Object.assign({}, this.GIAForm.value);
		form['date_from'] = this.GIAForm.value['date_from'].format('DD-MMM-YYYY');
		form['date_to'] = this.GIAForm.value['date_to'].format('DD-MMM-YYYY');
		form['include_fees'] = form['include_fees'] == '1' ? true : false;
		form['use_computed_quantity_left'] = form['use_computed_quantity_left'] == '1' ? true : false;
		switch(this.debugTypeSelected) {
			case 'GAINS_IN_ACCOUNT':
				return this.formGIA(form, token);
			case 'PRODUCTS_IN_ACCOUNT':
				return this.formPIA(form, token);
			case 'PRODUCTS_IN_ACCOUNT_LIGHT':
				return this.formPIA(form, token);
			case 'PERFORMANCES_AND_CONTRIBUTIONS':
				return this.formPAC(form, token);
			case 'NEW_PERFORMANCES_AND_CONTRIBUTIONS':
				return this.formNEWPAC(form, token);
			case 'PERFORMANCES_AND_CONTRIBUTIONS_CCY':
				return this.formPACC(form, token);
			case 'NEW_PERFORMANCES_AND_CONTRIBUTIONS_CCY':
				return this.formNEWPACC(form, token);
		}
	}
	
	formGIA(form: object, token: string): object {
		let submitForm = {
			'token' : token,
			'typeCalculateSever': 'DEBUG',
			'typeCalculate': 'GAINS_IN_ACCOUNT',
			'paramPeriod': '99',
			'start_date': form['date_from'],
			'end_date': form['date_to'],
			'id_product': form['id_product'],
			'modifyPanelSelectAccount': 'Multiple accounts',
			'id_account': form['id_account'].toString(),
			'exportToExcelFile': true,
			'include_fees': form['include_fees'],
		}
		return submitForm;
	}

	formPIA(form: object, token: string): object {
		return {
			'token' : token,
			'typeCalculateSever': 'DEBUG',
			'typeCalculate': this.debugTypeSelected,
			'paramPeriod': '99',
			'start_date': form['date_from'],
			'end_date': form['date_to'],
			'id_product': form['id_product'],
			'modifyPanelSelectAccount': 'Multiple accounts',
			'id_account': form['id_account'].toString(),
			'exportToExcelFile': true,
			'use_computed_quantity_left': form['use_computed_quantity_left'],
		}
	}
	formPAC(form:object, token: string): object {
		let category = (<HTMLInputElement>document.getElementById('category'));
		let selectedField = 'reset';
		let fieldValue = '';
		let submitForm;
		if (category.value !== '') {
			selectedField = category.value;
			fieldValue = (<HTMLInputElement>document.getElementById('categoryField')).value;
		}
		submitForm = {
			'token': token,
			'typeCalculateSever': 'DEBUG',
			'typeCalculate': 'PERFORMANCES_AND_CONTRIBUTIONS',
			'paramPeriod': '99',
			'start_date': form['date_from'],
			'end_date': form['date_to'],
			'modifyPanelSelectAccount': 'Multiple accounts',
			'id_account': form['id_account'].toString(),
			'id_category': selectedField,
			'exportToExcelFile': true,
			'use_computed_quantity_left': form['use_computed_quantity_left'],
		}
		submitForm['id_' + selectedField] = fieldValue;
		return submitForm;
	}
	formPACC(form:object, token: string): object {
		let category = (<HTMLInputElement>document.getElementById('category'));
		let selectedField = 'reset';
		let fieldValue = '';
		let submitForm;
		if (category.value !== '') {
			selectedField = category.value;
			fieldValue = (<HTMLInputElement>document.getElementById('categoryField')).value;
		}
		submitForm = {
			'token': token,
			'typeCalculateSever': 'DEBUG',
			'typeCalculate': 'PERFORMANCES_AND_CONTRIBUTIONS_CCY',
			'paramPeriod': '99',
			'start_date': form['date_from'],
			'end_date': form['date_to'],
			'modifyPanelSelectAccount': 'Multiple accounts',
			'id_account': form['id_account'].toString(),
			'id_category': selectedField,
			'exportToExcelFile': true,
			'use_computed_quantity_left': form['use_computed_quantity_left'],
		}
		submitForm['id_' + selectedField] = fieldValue;
		return submitForm;
	}

	formNEWPAC(form:object, token: string): object {
		let category = (<HTMLInputElement>document.getElementById('category'));
		let selectedField = 'reset';
		let fieldValue = '';
		let submitForm;
		if (category.value !== '') {
			selectedField = category.value;
			fieldValue = (<HTMLInputElement>document.getElementById('categoryField')).value;
		}
		submitForm = {
			'token': token,
			'typeCalculateSever': 'DEBUG',
			'typeCalculate': 'NEW_PERFORMANCES_AND_CONTRIBUTIONS',
			'paramPeriod': '99',
			'start_date': form['date_from'],
			'end_date': form['date_to'],
			'modifyPanelSelectAccount': 'Multiple accounts',
			'id_account': form['id_account'].toString(),
			'id_category': selectedField,
			'exportToExcelFile': true,
			'use_computed_quantity_left': form['use_computed_quantity_left'],
		}
		submitForm['id_' + selectedField] = fieldValue;
		return submitForm;
	}
	formNEWPACC(form:object, token: string): object {
		let category = (<HTMLInputElement>document.getElementById('category'));
		let selectedField = 'reset';
		let fieldValue = '';
		let submitForm;
		if (category.value !== '') {
			selectedField = category.value;
			fieldValue = (<HTMLInputElement>document.getElementById('categoryField')).value;
		}
		submitForm = {
			'token': token,
			'typeCalculateSever': 'DEBUG',
			'typeCalculate': 'NEW_PERFORMANCES_AND_CONTRIBUTIONS_CCY',
			'paramPeriod': '99',
			'start_date': form['date_from'],
			'end_date': form['date_to'],
			'modifyPanelSelectAccount': 'Multiple accounts',
			'id_account': form['id_account'].toString(),
			'id_category': selectedField,
			'exportToExcelFile': true,
			'use_computed_quantity_left': form['use_computed_quantity_left'],
		}
		submitForm['id_' + selectedField] = fieldValue;
		return submitForm;
	}
}