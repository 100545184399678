import {Component, OnInit} from '@angular/core';
import {UserService, PlanService, AlertService} from '../_services';
import {User} from '../_models/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Options, Globals} from '../shared';
import {formatDate} from '@angular/common';

declare function makeDatePicker(): any;

@Component({
  selector: 'app-update-user',
  templateUrl: './clone-user.component.html',
  styleUrls: ['./clone-user.component.css']
})
export class CloneUserComponent implements OnInit {
  submitted: boolean = false;
  formBuilder: FormBuilder;
  entityForm: FormGroup;
  entity = new User();
  loading = false;
  isError = false;
  errMsg = '';
  users = [];
  planList: any;
  statusList: Options[] = [
    {'title': 'Active', 'value': '1'},
    {'title': 'Inactive', 'value': '0'}
  ];
  paypalList: Options[] = [{'title': 'Paypal', 'value': '2'}];
  public userId = 53;

  constructor(private entityService: UserService, private router: Router, private route: ActivatedRoute,
              private alertService: AlertService, private globals: Globals) {
    this.formBuilder = new FormBuilder();
  }

  get f() {
    return this.entityForm.controls;
  }

  ngOnInit() {
    this.entityForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      fromuser: [Validators.required]
    });

    this.loadScript();
    this.getAllUsers();
  }

  loadScript() {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/js/jquery-ui.js';
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const element = document.createElement('link');
    element.rel = 'stylesheet';
    element.href = 'assets/css/jquery-ui.css';
    document.getElementsByTagName('head')[0].appendChild(element);
    setTimeout(() => {
      makeDatePicker();
    }, 1000);
  }


  onSubmit(entity) {
    this.submitted = true;
    if (this.entityForm.invalid) {
      return;
    }
    this.submitted = false;
    const values = this.entityForm.value;
    const data = {username: '', password: '', fromuser: 53};
    this.globals.processing = true;
    data.username = values.username;
    data.password = values.password;
    data.fromuser = this.userId;
    this.loading = true;
    this.isError = false;
    this.entityService.cloneUser(data).subscribe(res => {
      this.loading = false;
      if (res && res.status === 'success') {
        this.alertService.success(`The user has been clone`,true);
        this.backToList();

      }
    });

  }

  getAllUsers(): void {
    this.entityService.getAllInspectableUser().subscribe(users => {
      this.users = users;
    });
  }

  resetForm() {
    this.submitted = false;
    this.entityForm.reset();
  }

  generatePassword(length = 8) {
    let str = '';
    const chars = '!@#$%^&*()_+|0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charsN = chars.length;
    for (; str.length < length; str += chars.charAt(Math.floor(Math.random() * charsN))) {}
    this.entityForm.get('password').setValue(str);
  }

  backToList() {
    this.router.navigate(['users']);
  }

  onChangeUser(val) {
    this.userId = val;
  }
}
