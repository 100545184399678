import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Plan } from '../_models';
import { AlertService } from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  private apiUrl = environment.apiUrl + '/plans';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }

    getPlans (offset: number = 0, keyword:string = '', sortBy:string = 'name', sortType:string = 'asc', filter:string = 'any'): Observable<any> {
        let params = new HttpParams({encoder: new CustomURLEncoder() }).set('page', `${offset}`);
        if(keyword != ''){
          params = params.append('keyword', `${keyword}`);
        }
        if(filter == 'public'){
			params = params.append('is_public', '1');
        }
        params = params.append('sortBy', `${sortBy}`);
        params = params.append('sortType', `${sortType}`);
        return this.http.get<any>(this.apiUrl , {params})
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getPlan', [])))
          );
    }
    getPlan(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getPlan Error')))
        );
    }
    getUserByPlan(id: number,  page: number = 0, sortBy:string = 'name', sortType:string = 'asc'): Observable<any> {
      let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `10`);
      if(page){
        params = params.append('page', `${page}`);
      }
      params = params.append('sortBy', `${sortBy}`);
      params = params.append('sortType', `${sortType}`);
      return this.http.get<any>(this.apiUrl + '/' + id + '/users',{params}).pipe(
          map(response => {
              if (response && response.status == 'success') {
                  return response.data;
              }
              return [];
          },
          catchError(this.handleError<any>('getUsers Error')))
      );
    }
    addPlan (item: any): Observable<any> {
        return this.http.post<Plan>(this.apiUrl, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The plan has been added`,true)),
          catchError(this.handleError<any>('addPlan'))
        );
    }
    updatePlan (item: Plan): Observable<any> {
        return this.http.patch(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
          tap((item: Plan) => this.alertService.success(`The plan has been updated`)),
          catchError(this.handleError<any>('updatePlan'))
        );
    }
    updatePaypalState (id: number, status: string='ACTIVE'): Observable<any> {
        return this.http.post<Plan>(this.apiUrl + '/' + id + '/updatePaypalState', {state: status}, httpOptions).pipe(
          tap((item: any) => {
            this.alertService.success(`The plan has been activated`,true);
            return item;
          }),
          catchError(this.handleError<any>('active Plan'))
        );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          this.alertService.error(`${error}`);
          return of(result as T);
        };
    }
}
