import { Component, ViewChild, OnInit } from '@angular/core';
import { UserService, RoleService, AlertService, AuthenticationService } from '../_services';
import { Admin } from '../_models/admin';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Options, Globals } from '../shared';

@Component({
  selector: 'app-update-admin',
  templateUrl: './update-admin.component.html',
  styleUrls: ['./update-admin.component.css']
})
export class UpdateAdminComponent implements OnInit {
  submitted: boolean = false;
  resetPass = false;
  formBuilder: FormBuilder;
  entityForm: FormGroup;
  entity = new  Admin();
  backupEntity = new Admin();
  roleList: any;
  statusList:Options[] =[{'title':'Active','value':'1'},
	  	{'title':'Inactive','value':'0'}];

  constructor(private entityService: UserService, private router: Router, private roleService: RoleService, private route: ActivatedRoute, private alertService: AlertService, private globals: Globals) {
  	this.formBuilder = new FormBuilder();
  }
  get f() { return this.entityForm.controls; }
  ngOnInit() {
  	this.entityForm = this.formBuilder.group({
        name: [, Validators.required],
        //email: [null, { validators: [Validators.email, Validators.required, ValidateEmailExists], updateOn: 'blur' }],
        email: [null, { validators: [Validators.email, Validators.required]}],
        password: [null,[Validators.required, Validators.minLength(6),Validators.maxLength(16)]],
        roles: ['', Validators.required],
        status: ['', Validators.required]
    });
    this.getEntity();
    this.roleService.getRoles()
      .subscribe(roles => {
        this.roleList = roles.roles;
    });
  }
  getEntity(): void {
  	const id = +this.route.snapshot.paramMap.get('id');
  	if(id){
		this.entityService.getAdmin(id)
	      .subscribe(data => {
	      	data.roles = data.roles[0];
	        this.entity = data;
          	this.entityForm.reset(data);
	        this.resetPass = false;
	        this.resetPassword();
	        this.backupEntity = Object.assign({}, this.entity);
	      });
  	}else{
      this.entityForm.reset(this.entity);
    }
  }
  onSubmit(entity) {
        this.submitted = true;
        if (this.entityForm.invalid) {
            return;
        }
        this.submitted = false;
        let values = this.entityForm.value;
        let data  = Object.assign({}, entity);
        data.name = values.name;
        data.email = values.email;
        data.status = values.status;
        data.role = values.roles;
        data.password = values.password;
        this.globals.processing = true;
        if(data.id){
           data.role = values.roles;
           if(data.email == this.backupEntity.email){
				        delete data.email;
           }
           if(this.resetPass){
             delete data.password;
           }
           this.entityService.updateAdmin(data).subscribe(data => {this.getEntity();});
        }else{
           data.password = values.password;
           this.entityService.addAdmin(data).subscribe(data => {
           		if(data && data.status == 'success'){
           			this.backToList();
           		}
           });
        }
    }
    deleteEntity(entity){
      this.entityService.deleteAdmin(entity.id).subscribe(data => {this.getEntity();});
    }
    resetForm(){
      this.submitted = false;
      this.entityForm.reset(this.backupEntity);
    }
    resetPassword(){
    	this.resetPass = !this.resetPass;
    	if(this.resetPass){
			  this.entityForm.get('password').clearValidators();
    	}else{
 			  this.entityForm.get('password').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(16)]);
    	}
    	this.entityForm.get('password').updateValueAndValidity();
	    this.entityForm.updateValueAndValidity();
    }
    backToList() {
      this.router.navigate(['admins']);
    }
}
