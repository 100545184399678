import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Role } from '../_models';
import { AlertService } from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private apiUrl = environment.apiUrl + '/roles';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }

    getRoles (offset: number = 0, limit: number = 10, keyword:string = '', sortBy:string = 'name', sortType:string = 'asc'): Observable<any> {

        let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `${limit}`).set('page', `${offset}`);

        //let params = {limit:limit,page:offset,sortBy:'id',keyword: ''} ;
        if(keyword != ''){
          params = params.append('keyword', `${keyword}`);
        }
        params = params.append('sortBy', `${sortBy}`);
        params = params.append('sortType', `${sortType}`);
        return this.http.get<any>(this.apiUrl + '/filter' , {params})
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getRole', [])))
          );
    }
    getRole(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getRole Error')))
        );
    }
    getAdminByRole(id: number,  page: number = 0): Observable<any> {
      let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `1000`);
      if(page){
        params = params.append('page', `${page}`);
      }
        return this.http.get<any>(this.apiUrl + '/' + id + '/admins',{params}).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getRole Error')))
        );
    }
    addRole (item: any): Observable<any> {
        return this.http.post<Role>(this.apiUrl, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The role has been added`,true)),
          catchError(this.handleError<any>('addRole'))
        );
    }
    givePermission (item: any): Observable<any> {
        return this.http.post<any>(this.apiUrl + '/givePermission', item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The permission has been given`)),
          catchError(this.handleError<any>('givePermission'))
        );
    }
    revokePermission (item: any): Observable<any> {
        return this.http.post(this.apiUrl + '/revokePermission', item, httpOptions).pipe(
          tap((item: any) => {}),
          catchError(this.handleError<any>('revokePermission'))
        );
    }
    updateRole (item: Role): Observable<any> {
        return this.http.patch(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
          tap((item: Role) => this.alertService.success(`The role has been updated`)),
          catchError(this.handleError<any>('updateRole'))
        );
    }
    deleteRole(id: number): Observable<any> {
        return this.http.delete<any>(this.apiUrl + '/' + id).pipe(
          map(response => {return null;},
          catchError(this.handleError<any>('deleteRole Error')))
        );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          //this.alertService.error(`UserService: ${operation} failed: ${error}`);
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}
