import { Component, OnInit} from '@angular/core';
import { RoleService, PermissionService, AlertService, AuthenticationService } from '../_services';
import { Role } from '../_models/role';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatTableDataSource, MatDialog, MatPaginatorModule, MatPaginator } from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import { Options, Globals } from '../shared';
declare var $: any;
@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {
  submitted: boolean;
  formBuilder: FormBuilder;
  roleForm: FormGroup;
  role  = new  Role();
  backupRole: any;
  id = 0;
  statusList:Options[] =[{'title':'Active','value':'1'},
      {'title':'Inactive','value':'0'}];
  permissions: string[] = [];
  groupPermissions: any;
  displayedColumns: string[] =  ['id','name'];
  dataSource = new MatTableDataSource();
  totalItems = 100;
  pageSize = 10;
  constructor(private roleService: RoleService, private permService: PermissionService, private router: Router, private route: ActivatedRoute, private alertService: AlertService, private globals: Globals) {
  	this.formBuilder = new FormBuilder();
  }
  get f() { return this.roleForm.controls; }

  ngOnInit() {
  	this.getRole();
  	this.roleForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        status: ['', Validators.required],
    });
  }
  getRole(): void {
  	this.id = +this.route.snapshot.paramMap.get('id');
  	if(this.id ){
		this.roleService.getRole(this.id)
	      .subscribe(data => {
	        this.role = data.role;
          this.permissions = Object.assign([], data.role.permissions);
          this.roleForm.reset(this.role);
          this.backupRole = Object.assign({}, this.role);
          this.permService.getPermissions().subscribe(data=>{
            this.groupPermissions = data;
          });
	      });
        this.roleService.getAdminByRole(this.id)
        .subscribe(data => {
          this.dataSource.data = data.admins;
          this.totalItems = data.pagination.total;
        });
  	}
  }
  isSelected(permName){
    if(permName.indexOf('view') > -1){
      let temp = permName.replace('view ','');
      if(this.permissions.indexOf('add ' + temp) >-1 || this.permissions.indexOf('edit ' + temp) >-1 || this.permissions.indexOf('delete ' + temp) >-1 ){
        $('input[value="view ' + temp + '"]').attr('disabled','disabled');
        return true;
      }
    }
    return (this.permissions.indexOf(permName) > -1);
  }
  toggleVisibility(e){
    if( e.target.checked){
      if(e.target.value.indexOf('view') !== 0) {//check on add, edit, delete
        let viewEl = $(e.target).closest('div.col-xs-12').find('input:eq(0)');
        viewEl.prop('checked', true);
        viewEl.attr('disabled','disabled');
        if(this.permissions.indexOf(viewEl.val()) == -1){
          this.permissions.push(viewEl.val());
        }
      }
      this.permissions.push(e.target.value);
    }else{
      if(this.permissions.indexOf(e.target.value) > -1){
        this.permissions.splice(this.permissions.indexOf(e.target.value), 1);
        let viewEl = $(e.target).closest('div.col-xs-12').find('input:eq(0)');
        if(viewEl.prop('checked')){
          if($(e.target).closest('div.col-xs-12').find('input:checked').length == 1){
            viewEl.removeAttr('disabled');
          }
        }
      }
    }
  }
  onSubmit(role) {
    this.submitted = true;
    if (this.roleForm.invalid) {
      window.scroll(0,0);
      return;
    }
    let data  = Object.assign({}, role);
    let values = this.roleForm.value;
    data.name = values.name;
    data.description = values.description;
    data.status = parseInt(values.status) || 0;
    if(this.permissions.length){
      data.permission = this.permissions.join(',');
    }else{
      delete data.permission;
    }
    if(data.name == this.backupRole.name){
        delete data.name;
    }
    this.globals.processing = true;
    this.roleService.updateRole(data).subscribe(response => {
       if(response && response.status == 'success'){
          if(!data.permission){
            this.roleService.revokePermission({role:response.data.role.name,'permission': '*'}).subscribe(response => {
              this.role = response.data.roles;
              this.permissions = Object.assign([], this.role.permissions);
              this.backupRole = Object.assign({}, this.role);
            });
          }else{
            this.role = response.data.role;
            this.permissions = Object.assign([], this.role.permissions);
            this.backupRole = Object.assign({}, this.role);
          }
       }
    });
    return;
  }
  resetForm(){
    this.submitted = false;
    this.roleForm.reset(this.backupRole);
    this.permService.getPermissions().subscribe(data=>{
      this.groupPermissions = data;
      this.permissions = Object.assign([], this.backupRole.permissions);
    });
    window.scroll(0,0);
  }

  onCancel() {
    this.router.navigate(['roles']);
  }

  onPaginateChange(pageEvent):void {
    let currentPage = pageEvent.pageIndex + 1;
    this.roleService.getAdminByRole(this.id, currentPage).subscribe(list=>{
      this.dataSource.data = list.admins;
    });
  }
}
