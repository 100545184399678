import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService } from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private apiUrl = environment.apiUrl + '/currencies';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }

    getCurrencies (offset: number = 0, limit: number = 10, keyword: string = '', sortBy: string = 'name',
                   sortType: string = 'asc'): Observable<any> {

        let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `${limit}`).set('page', `${offset}`);
        if(keyword != ''){
          params = params.append('filter[shortcode]', `${keyword}`);
          params = params.append('filter[name]', `${keyword}`);
          params = params.append('filter[operator]', `or`);
        }
        params = params.append('sortBy', `${sortBy}`);
        params = params.append('sortType', `${sortType}`);
        return this.http.get<any>(this.apiUrl , {params})
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getCurrency', [])))
          );
    }

    getAllCurrencies(offset: number = 0, limit: number = 100, sortBy: string = 'name', sortType: string = 'asc'): Observable<any> {
      let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `${limit}`).set('page', `${offset}`);
      params = params.append('sortBy', `${sortBy}`);
      params = params.append('sortType', `${sortType}`);
      return this.http.get<any>(this.apiUrl , {params})
        .pipe(
          map(response => {
              if (response && response.status === 'success') {
                return response.data;
              }
              return [];
            },
            catchError(this.handleError('getAllCurrencies', [])))
        );
    }

    getCurrency(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getCurrency Error')))
        );
    }
    updateCurrency (item: any): Observable<any> {
        return this.http.put(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The currency has been updated`)),
          catchError(this.handleError<any>('updateCurrency'))
        );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          //this.alertService.error(`UserService: ${operation} failed: ${error}`);
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}
