import { Component, OnInit} from '@angular/core';
import { FaqService, PermissionService, AlertService, AuthenticationService } from '../_services';
import { MatDialog } from '@angular/material';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Faq } from '../_models/faq';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Options } from '../shared/option';
import { DialogComponent } from '../dialog/dialog.component';
import { Globals } from '../shared';

import { AngularEditorToolbarComponent } from '@kolkov/angular-editor';

AngularEditorToolbarComponent.prototype.onFileChanged = /**
     * Upload image when file is selected
     * @param {?} event
     * @return {?}
     */
    function (event) {
        var _this = this;
        /** @type {?} */
        var file = event.target.files[0];
        if (file.type.includes("image/")) {
           if ( /\.(jpe?g|png|gif)$/i.test(file.name) ) {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                _this.editorService.insertImage(this.result);
            }, false);
            reader.readAsDataURL(file);
          }
        }
    };

declare function scrollUpDown():any;
@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.css']
})
export class AddFaqComponent implements OnInit {
  submitted: boolean;
  formBuilder: FormBuilder;
  faqForm: FormGroup;
  faq  = new  Faq();
  topics:any;
  backupFaq = new Faq();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    //uploadUrl: 'uploads/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor(private faqService: FaqService, private router: Router, private route: ActivatedRoute,  public dialog: MatDialog, private globals: Globals) { 
  	this.formBuilder = new FormBuilder();
  }
  get f() { return this.faqForm.controls; }

  ngOnInit() {
    setTimeout(() =>{
        scrollUpDown();
      }, 500);
  	this.faqForm = this.formBuilder.group({
        id_faq_topic: ['', Validators.required],
        subject: ['', Validators.required],
        details: ['', Validators.required],
    });
    this.getFaq();
    this.faqService.getTopics()
      .subscribe(topics => {
      	this.topics = topics;
      });
  }
  openConfirmDialog(faqId): void {
    if(!faqId) return;
    const dialogRef = this.dialog.open(DialogComponent , {
      width: '350px',
      data: {type:true,message:'Are you sure you want to delete this faq?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.faqService.deleteFaq(faqId).subscribe(data => {
          this.router.navigate(['faqs']);
        }); 
      }
    });
  }
  getFaq(): void {
  	const id = +this.route.snapshot.paramMap.get('id');
  	if(id){
		this.faqService.getFaq(id)
	      .subscribe(data => { 
	        this.faq = data.faq;  
          	this.faqForm.reset(this.faq);
          	this.faqForm.get('id_faq_topic').disable();
	        this.backupFaq = Object.assign({}, this.faq);
	      });
  	}else{
      this.faqForm.reset(this.faq);
    }
  }
  onSubmit(faq) {
  	this.submitted = true;
    let values = this.faqForm.value;
    if(values.details == '<br>'){
      this.faqForm.get('details').setValue('');
    }
    if (this.faqForm.invalid) {
      window.scroll(0,0);
      return;
    }
    this.globals.processing = true;
    if(faq.id){
    	let data  = Object.assign({}, faq);
    	data.subject = values.subject;
    	data.details = values.details;
    	this.faqService.updateFaq(data).subscribe(data => {this.getFaq();}); 
    }else{
    	this.faqService.addFaq(values).subscribe(data => {
  			if(data && data.status == 'success'){
  				 this.backToList();
  			}
  		});
    }
  }
  backToList(){
     this.router.navigate(['faqs']);
  }
  resetForm(){
     this.submitted = false;
     this.faqForm.reset(this.backupFaq);
     window.scroll(0,0);
  }
}
